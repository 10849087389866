import axios from "axios";
import { store } from "../store/store";

const axiosInstance2 = axios.create({
  // baseURL: `https://api-staging.viosa.in`,
  baseURL: `https://api.viosa.in`,

  // baseURL: `http://localhost:3200`,
});

axiosInstance2.interceptors.request.use((config) => {
  // const state = store.getState();
  // const token = state.auth.auth.idToken;
  // config.params = config.params || {};
  // config.params['auth'] = token;
  return config;
});

export default axiosInstance2;
