import axios from 'axios';
import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import VideoPlayer from './VideoPlayer';
import { toast } from 'react-toastify';
import DeleteModal from '../../Common/Components/DeleteModal';

function InterviewStep3({ interviewState, setinterviewState }) {

    const handleAddQuestion = () => {
        const newId = interviewState?.interviewquestions?.length + 1;
        setinterviewState({ ...interviewState, interviewquestions: [...interviewState?.interviewquestions, { id: newId, question: '', keyword: '', duration: '', answer: '', videoQuestion: '' }] });
    };


    const handleChange = (id, field, value) => {
        console.log(value)
        const questions = interviewState?.interviewquestions?.map(question =>
            question.id === id ? { ...question, [field]: value } : question
        )
        setinterviewState({ ...interviewState, interviewquestions: questions })
    };

    const handleRemoveQuestion = (id) => {
        const questions = interviewState?.interviewquestions?.filter(question => question.id !== id)
        setinterviewState({ ...interviewState, interviewquestions: questions })
        toast.success(`Question ${id} deleted` )
    };




    // deletion modal

     // delete Skill type modal
     const [showDelete, setshowDelete] = useState(false)
    const [deleteQuestionData, setdeleteQuestionData] = useState(null)
     const handleshowDelete = () => {
         setshowDelete(true)
     }
     const HideDeleteModal = () => {
         setshowDelete(false)
     }
     const handleDeleteDecision = (value) => {
         if (value) {
            //  handleDeleteSkillType(deleteSKillTypeData._id)
             // console.log("delte")
             handleRemoveQuestion(deleteQuestionData.id)
         } else {
             toast.error("No Question Deleted")
             // console.log("delte")
         }
     }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <h4>Interview Questions</h4>
                <button onClick={handleAddQuestion} className='common-btn p-2 px-4 rounded-2 '>Add Interview Question</button>
            </div>
            {interviewState?.interviewquestions?.map(question => (
                <div key={question.id}>
                    <div className='mt-5 d-flex justify-content-between '><h6>Q.{question.id}</h6> <button className='bg-danger p-1 rounded-2' onClick={() => {
                        setdeleteQuestionData(question)
                        handleshowDelete()
                        }}>Remove {question.id}</button></div>
                    <Form.Group className="w-100" controlId="exampleForm.ControlInput1">

                        <Form.Label>Questions {question.id}</Form.Label>
                        <Form.Control type="text" onChange={e => handleChange(question?.id, 'question', e.target.value)} value={question?.question} className='border border-dark' placeholder="Write Question " />
                    </Form.Group>
                    <Form.Group className="w-100 mt-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Keywords</Form.Label>
                        <Form.Control type="text" onChange={e => handleChange(question?.id, 'keyword', e.target.value)} value={question?.keyword} className='border border-dark' placeholder="Keywords (seprated by comma(',')) " />
                    </Form.Group>

                    <Form.Group className="w-100 mt-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Duration</Form.Label>
                        <Form.Control type="number" onChange={e => handleChange(question?.id, 'duration', e.target.value)} value={question?.duration} className='border border-dark' placeholder="Seconds" />
                    </Form.Group>

                    <Form.Group className="w-100 mt-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Suggested Answer {question?.id}</Form.Label>
                        <Form.Control type="text" onChange={e => handleChange(question.id, 'suggestedAnswer', e.target.value)} value={question?.suggestedAnswer} className='border border-dark' placeholder="write your answer" />
                    </Form.Group>


                    <Form.Group className="w-100 mt-3 d-flex flex-column" controlId="exampleForm.ControlInput1">
                        <Form.Label>Upload Video</Form.Label>
                        <Form.Control type="file" onChange={e => handleChange(question.id, 'videoQuestion', e.target.files[0])} className='border border-dark' placeholder="video" />
                    </Form.Group>
                    {
                        !!question.videoQuestion &&
                        <VideoPlayer videourl={question?.videoQuestion} />
                    }
                </div>
            ))}
            <div className='d-flex justify-content-end mt-3 w-100'>

            </div>
            <DeleteModal handleDeleteDecision={handleDeleteDecision} text={`Do you want to delete ${deleteQuestionData?.id}`} showDelete={showDelete} HideDeleteModal={HideDeleteModal} />

            
            {/* <button className='common-btn'>Upload Videos</button> */}
        </div>
    )
}

export default InterviewStep3
