/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { useState } from "react";
import { MdOutlineGroupAdd } from "react-icons/md";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import { FaRegBuilding, FaRegUserCircle } from "react-icons/fa";
import { FaWpforms } from "react-icons/fa";
import { checkSidebarTool } from "../../../services/apis";
import { PiStudentLight } from "react-icons/pi";
import { SiCoursera } from "react-icons/si";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { CiMoneyBill } from "react-icons/ci";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { GoStack } from "react-icons/go";
import { MdCorporateFare } from "react-icons/md";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() { }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const ROLE = localStorage.getItem('role')
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    // var handleheartBlast = document.querySelector('.heart');
    //       function heartBlast() {
    //           return handleheartBlast.classList.toggle("heart-blast");
    //       }
    //       handleheartBlast.addEventListener('click', heartBlast);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
    "/",
    "dashboard",
    "dashboard-dark",
    "guest-list",
    "guest-detail",
    "concierge",
    "room-list",
    "reviews",
    "task",
  ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    students = ['/students'],
    redux = ["redux-form", "redux-wizard", "todo"],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
      "form"
    ],
    table = ["users", "user", "user/63007"],
    revenue = ["revenue"],
    enquiry = ["query"],

    staffManage = ["staffManage"],
    courseManage = ["course", "63"],
    coupon = ["coupons"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];

  // console.log(courseManage.includes(path));
  const [dropdown2, setDropdown2] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const token = localStorage.getItem("token");

  //  interviewpro and resumatic dropdown

  const pathname = window.location.pathname
  const [SidebarTools, setSidebarTools] = useState(null)
  useEffect(() => {
    if (!!localStorage.getItem("userId")) {
      checkTools()
    }
  }, [])
  const checkTools = async () => {
    try {
      const payload = {
        collegeId: localStorage.getItem("userId"),
      };
      const res = await checkSidebarTool(payload);
      setSidebarTools(res.data.tools);
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li
            onClick={() => {
              setDropdown(false);
            }}
            className={`${window.location.pathname === "/dashboard" && dropdown === false
              ? "mm-active"
              : ""
              }`}
          >
            <Link to="/dashboard" className="ai-icon">
              <MdOutlineDashboardCustomize color={pathname === '/dashboard' && "white"} fontSize={38} className="me-4" />
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          {
            ROLE === "ADMIN" &&
            <>
              <li onClick={() => {
                setDropdown(false);
              }} className={`${table.includes(path) && dropdown === false ? "mm-active" : ""}`}
              >
                <Link to="/users" className="ai-icon">


                  <FiUsers color={pathname === '/users' && "white"} fontSize={38} className="me-4" />

                  {/* <i className="flaticon-381-user-1"></i> */}
                  <span className="nav-text">Users</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${revenue.includes(path) && dropdown === false
                  ? "mm-active"
                  : ""
                  }`}
              >
                <Link to="/revenue" className="ai-icon">
                  <CiMoneyBill color={pathname === '/revenue' && "white"} fontSize={38} className="me-4" />
                  {/* <i className="flaticon-381-price-tag"></i> */}
                  <span className="nav-text">Revenue </span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${courseManage.includes(path) && dropdown === false
                  ? "mm-active"
                  : ""
                  }`}
              >
                <Link to="/course" className="ai-icon">
                  {/* <i className="flaticon-381-play-button"></i> */}
                  <SiCoursera color={pathname === '/course' && "white"} fontSize={38} className="me-4" />

                  <span className="nav-text">Courses</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${coupon.includes(path) && dropdown === false ? "mm-active" : ""
                  }`}
              >
                <Link to="/coupons" className="ai-icon">
                  <MdOutlineGeneratingTokens color={pathname === '/coupons' && "white"} fontSize={38} className="me-4" />

                  {/* <i className="flaticon-381-coupon"></i> */}
                  <span className="nav-text">Coupons</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${enquiry.includes(path) && dropdown === false
                  ? "mm-active"
                  : ""
                  }`}
              >
                <Link to="/query" className="ai-icon">
                  {/* <i className="flaticon-381-list-1"></i> */}
                  <FaRegCircleQuestion color={pathname === '/query' ? "white" : ""} fontSize={38} className="me-4" />

                  <span className="nav-text">Query</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${forms.includes(path) && dropdown === false
                  ? "mm-active"
                  : ""
                  }`}
              >
                <Link to="/form" className="ai-icon">
                  <FaWpforms color={pathname === '/form' ? "white" : ""} fontSize={38} className="me-4" />
                  {/* <i className="flaticon-381-list-1"></i> */}
                  <span className="nav-text">Form</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${pathname.includes("colleges") ? "mm-active" : ""
                  }`}
              >
                <Link to="/colleges" className="ai-icon">
                  <FaRegBuilding color={pathname === '/colleges' ? "white" : ""} fontSize={38} className="me-4" />
                  <span className="nav-text">Colleges</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${pathname.includes("corporate") ? "mm-active" : ""
                  }`}
              >
                <Link to="/corporate" className="ai-icon">
                  <MdCorporateFare color={pathname === '/corporate' ? "white" : ""} fontSize={38} className="me-4" />
                  <span className="nav-text">Corporate</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${pathname.includes('batches') ? "mm-active" : ""
                  }`}
              >
                <Link to="/batches" className="ai-icon">
                  {/* <MdOutlineGroupAdd style={{ color: "#C5BFBF", fontSize: "5rem" }} className="me-3" fontSize={40} /> */}
                  <GoStack color={pathname === '/batches' ? "white" : ""} fontSize={38} className="me-4" />

                  <span className="nav-text">Batches</span>
                </Link>
              </li>
            </>
          }
          {ROLE === "COLLEGE" && (
            <>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${pathname.includes('students') ? "mm-active" : ""
                  }`}
              >
                <Link to="/students" className="ai-icon">
                  <PiStudentLight color={pathname.includes('students') && "white"} fontSize={38} className="me-4" fontWeight={800} />

                  <span className="nav-text">Students</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setDropdown(false);
                }}
                className={`${pathname.includes('courses') && dropdown === false
                  ? "mm-active"
                  : ""
                  }`}
              >
                <Link to="/college/courses" className="ai-icon">
                  <SiCoursera color={pathname.includes('courses') && "white"} fontSize={38} className="me-4" />
                  <span className="nav-text">Courses</span>
                </Link>
              </li>
              <li onClick={() => { setDropdown(false) }} className={`${pathname.includes('batches') ? "mm-active" : ""
                }`}
              >
                <Link to="/college/batches" className="ai-icon">
                  <MdOutlineGroupAdd color={pathname.includes('batches') && "white"} fontSize={38} className="me-4" />

                  <span className="nav-text">Batches</span>
                </Link>
              </li>
            </>
          )}

          <li className={`${dropdown === true ? "mm-active" : ""}`}>
            {((ROLE === "COLLEGE" && !!SidebarTools?.find(item => item.name === "Interview Pro")) ||
              ROLE === "ADMIN") && (
                <span
                  className="ai-icon"
                  onClick={() => {
                    setDropdown((state) => (state ? false : true));
                  }}
                  style={{ fontSize: "1rem", padding: "0.9375rem 1.25rem", background: window.location.pathname.includes('/interview-pro') ? "#521986" : '' }}
                >
                  <i
                    className="bi bi-chevron-down"
                    style={{ marginRight: "1.3125rem" }}
                  ></i>
                  <span className="nav-text">Interview Pro</span>
                </span>
              )}
            {dropdown === true ? (
              <div className="mt-2 rounded py-3" style={{ background: "#ebebeb" }}>
                {
                  ROLE === "ADMIN" &&
                  <>
                    <Link
                      to="/interview-pro/dashboard"
                      className={`rounded ${pathname === "/interview-pro/dashboard"
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    >
                      <span>Dashboard</span>
                    </Link>
                    <Link
                      to={"/interview-pro/interview/add/new-mock"}
                      className={`rounded ${pathname.includes("/interview-pro/interview/add/new-mock")
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    >
                      <span>Create Interview</span>
                    </Link>
                    <Link
                      to={"/interview-pro/myinterview"}
                      className={`rounded ${pathname === "/interview-pro/myinterview"
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    >
                      <span>My Interviews</span>
                    </Link>
                    <Link
                      to={"/interview-pro/users"}
                      className={`rounded ${pathname === "/interview-pro/users"
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    >
                      <span>Users</span>
                    </Link>
                    <Link
                      to={"/interview-pro/skills"}
                      className={`rounded ${pathname === "/interview-pro/skills"
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    >
                      <span>Skills</span>
                    </Link>
                  </>
                }

                {
                  ROLE === "COLLEGE" &&
                  <>
                    <Link
                      to={"/college/interview-pro/users"}
                      className={`rounded ${pathname === "/college/interview-pro/users"
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    >
                      <PiStudentLight color={pathname.includes('students') && "white"} fontSize={22} className="me-4" fontWeight={800} />

                      <span>Students</span>
                    </Link>
                  </>
                }

              </div>
            ) : (
              <></>
            )}
          </li>

          <li
            onClick={() => {
              setDropdown2(dropdown2 === true ? false : true);
            }}
            className={`${dropdown2 === true ? "mm-active" : ""} mt-1`}
          >
            {((ROLE === "COLLEGE" && !!SidebarTools?.find(item => item.name === "Resumatic AI")) ||
              ROLE === "ADMIN") && (
                <span
                  className="ai-icon"
                  style={{
                    fontSize: "1rem", padding: "0.9375rem 1.25rem",
                    background: window.location.pathname.includes('/resumatic') ? "#521986" : ''
                  }}
                >
                  <i
                    className="bi bi-chevron-down"
                    style={{ marginRight: "1.3125rem" }}
                  ></i>
                  <span className="nav-text">Resumatic</span>
                </span>
              )}
            {dropdown2 === true ? (
              <div className="mt-2  rounded " style={{ background: "#ebebeb" }}>
                {
                  ROLE === "ADMIN" &&
                  <>

                    <Link
                      to="/resumatic/dashboard"
                      className={`rounded my-2 mt-3 ${pathname === "/interview-pro/dashboard"
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    >
                      <span>Dashboard</span>
                    </Link>
                    <Link
                      to={"/resumatic/users"}
                      className={`rounded ${pathname === "/interview-pro/interview/add"
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    >
                      <span>Users</span>
                    </Link>
                    {/* <Link
                      to={"/resumatic/aicreditsb"}
                      className={`rounded ${pathname === "/interview-pro/myinterview"
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    >
                      <span>Ai Credits</span>
                    </Link> */}

                    <Link
                      to={"/interview-pro/candidate"}
                      className={`rounded ${pathname === "/interview-pro/candidate"
                        ? "navbar_bg_active"
                        : ""
                        } text-black `}
                    ></Link>
                  </>}
                {
                  ROLE === "COLLEGE" &&
                  <>     <Link
                    to={"/college/resumatic/users"}
                    className={`rounded ${pathname === "/college/resumatic/users"
                      ? "navbar_bg_active"
                      : ""
                      } text-black `}
                  >
                    <PiStudentLight color={pathname.includes('students') && "white"} fontSize={22} className="me-4" fontWeight={800} />

                    <span>Students</span>
                  </Link>
                  </>
                }
              </div>
            ) : (
              <></>
            )}
          </li>

        </MM>
        <div className="copyright">
          <p>
            <strong>Viosa</strong> © {new Date().getFullYear()} All Rights
            Reserved
          </p>
          {/* <p className="fs-12">Made with <span className="heart"></span> by DexignZone</p> */}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
