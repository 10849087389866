import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowActive } from "../../PluginsMenu/Nestable/utils";
import { UploadForm } from "./UploadForm";
import pdfIcon from "../../../../icons/pdf.svg";
import { Button, Modal } from "react-bootstrap";
import { Video } from "./Chapter/Video";
import { Assignment } from "./Chapter/Assignment";
import {
  AddActiveDate,
  AddActiveDateChapter,
  addChapterAction,
  addChapterContent,
  AddDripByChapter,
  AddDripByDateChapter,
  removeChapter,
} from "../../../../store/actions/section";
import PDF from "./Chapter/PDF";
import { toast, ToastContainer } from "react-toastify";
import { Embedded, URL } from "./Chapter/Embedded";
import { PageBuilder } from "./Chapter/PageBuilder";

export const ChapterItem = ({ index, UploadForm, item, chapter, isExpand }) => {
  const [isExpanded1, setIsExpanded1] = useState(false);
  const { isExpandedValue } = useSelector((state) => state.section);
  const [screen, setScreen] = useState([]);
  const [count, setCount] = useState(0);

  const [isVideo, setIsVideo] = useState(true);
  const [otherFile, setOherFile] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [chapterID, setChapterID] = useState(0);
  const [inputData, setInputData] = useState("");
  const [type, setType] = useState("");
  const [dripBy, setDripData] = useState("");
  const [selected, setSelected] = useState(
    (chapter.url && "6") ||
      (chapter.videoLink && "1") ||
      (chapter.embeddedLink && "3") ||
      (chapter.pdfLink && "2") ||
      (chapter.pageBuilder && "4") ||
      (chapter.assignment && "5") ||
      "1"
  );
  const dispatch = useDispatch();
  const [dripData1, setDripData1] = useState({});
  const { videoLink } = useSelector((state) => state.section);
  const cId = window.location.pathname.split("/")[2];
  const [data, setData] = useState("");
  const formDataHandler = (e) => {
    setData(e.target.value);
    const newItems = {
      chapterName: data || e.target.value,
      courseId: cId,
      chapterID: chapter.chapterID,
      sectionID: chapter.section,
    };
    dispatch(addChapterContent(newItems));
  };

  const list = [
    {
      id: "1",
      title: "Video",
      icon: "fa-solid fa-video",
    },
    {
      id: "2",
      title: "Pdf",
      icon: "fa-solid fa-file-pdf",
    },
    {
      id: "6",
      title: "URL",
      icon: "fa-solid fa-link",
    },
    {
      id: "4",
      title: "Assignment",
      icon: "fa-solid fa-list-check",
    },
    {
      id: "5",
      title: "Page Builder",
      icon: "fa-solid fa-file-word",
    },
  ];

  useEffect(() => {
    switch (selected) {
      case "1":
        setScreen(
          <Video
            index={index + 1}
            chapterID={chapter.chapterID}
            sectionID={chapter.section}
            videoLINK={chapter.videoLink}
            otherLink={chapter.otherFile}
          />
        );
        break;
      case "2":
        setScreen(
          <PDF
            index={index + 1}
            chapterID={chapter.chapterID}
            sectionID={chapter.section}
            section={chapter.section}
            pdfLINK={chapter.pdfLink}
          />
        );
        break;
      case "3":
        setScreen(<Embedded />);
        break;
      case "6":
        setScreen(
          <URL
            name={chapter.url}
            index={index + 1}
            chapterID={chapter.chapterID}
            sectionID={chapter.section}
          />
        );
        break;
      case "4":
        setScreen(
          <Assignment
            chapterID={chapter.chapterID}
            sectionID={chapter.section}
            assesmentList={chapter.assessment}
          />
        );
        break;
      case "5":
        setScreen(
          <PageBuilder
            chapterID={chapter.chapterID}
            sectionID={chapter.section}
            section={chapter.section}
          />
        );
        break;
      default:
    }
  }, [
    selected,
    chapter.pdfLink,
    chapter.section,
    chapter.videoLink,
    chapter.assessment,
  ]);

  const addChapter = () => {
    setCount((count) => count + 1);
    const newItems = {
      chapterName: data,
      courseId: cId,
      chapterID: chapter.chapterID,
      name: `abc${count}`,

      sectionID: chapter.section,
    };
    dispatch(addChapterContent(newItems));
    setIsExpanded1(false);
    toast.success(`✔ ${data} chapter addedd!`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const removeChapterItem = (id) => {
    swal({
      title: "Are you sure?",
      text: "This chapter Will be deleted!",
      icon: "warning",
      buttons: ["No, cancel it!", "Yes, I am sure!"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Delete Chapter!",
          text: "Chapter Deleted Successfully !",
          icon: "success",
        }).then(function () {
          dispatch(
            removeChapter({ id: id, courseId: cId, section: chapter.section })
          );
        });
      } else {
        swal("Cancelled", "Your chapter is safe :)", "error");
      }
    });
  };

  const dripDateHandler = (e) => {
    const date = e.target.value;
    setDripData1({
      sectionID: chapter.section,
      chapterID: chapter.chapterID,
      activeDate: date,
      courseId: cId,
    });
  };
  const modalOpenFunction = (item) => {
    setModalOpen(true);
    setChapterID(item);
  };
  const dripByHandler = (e) => {
    setDripData(e.target.value);
    if (e.target.value === "days") {
      setType("availableAfterDays");
    } else {
      setType("availableAfterWeeks");
    }
  };
  const dripInputHandler = (e) => {
    setInputData(e.target.value);
  };

  const saveDrip = (e) => {
    e.preventDefault();
    const dripValue = {
      sectionID: chapter.section,
      chapterID: chapter.chapterID,
      courseId: cId,
      drip: {
        [dripBy]: true,
      },
    };
    const activeDateForType = {
      [type]: Number(inputData),
      sectionID: chapter.section,
      chapterID: chapter.chapterID,
      courseId: cId,
    };

    dispatch(AddActiveDateChapter(dripData1));
    dispatch(AddDripByChapter(dripValue));
    dispatch(AddDripByDateChapter(activeDateForType));
    toast.success("Drip Added", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <Modal className="fade bd-example-modal-lg" show={modalOpen} size="lg">
        <Modal.Header>
          <Modal.Title className="text-center">Drip Chapter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setModalOpen(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="d-flex" style={{ alignItems: "baseline" }}>
              <input type="checkbox" />
              <p className="px-2">Start Drip Chapter</p>
            </div>
            <p>
              A way to schedule timely realease of your section . We send a
              alert to student when the section is available.
            </p>
            <div>
              <div>
                <div className="d-flex" style={{ alignItems: "baseline" }}>
                  <input type="radio" name="radio" />
                  <p className="px-2">Drip by </p>
                </div>

                <div>
                  <select
                    className="select-input"
                    onChange={(e) => dripByHandler(e)}
                  >
                    <option>Select</option>
                    <option value="days">Day</option>
                    <option value="weeks">Week</option>
                    <option value="completion">Completion</option>
                  </select>
                  <input
                    type="text"
                    onChange={(e) => dripInputHandler(e)}
                    className="modal-input"
                  />
                </div>
              </div>
              <div className="d-flex" style={{ alignItems: "baseline" }}>
                <input type="radio" name="radio" />
                <p className="px-2">Drip by date</p>
                <input
                  type="date"
                  onChange={dripDateHandler}
                  className="modal-date"
                />
              </div>
              <p>Unlock section on specific date </p>
            </div>
            <button className="btn btn-primary" onClick={saveDrip}>
              Save
            </button>
          </form>
        </Modal.Body>
      </Modal>

      {!isExpand && (
        <div className="border-1 px-3 mb-2 animated_card">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <p>{data}</p>
          <strong className="text-danger">
            {(chapter.videoLink && "Video") ||
              (chapter.pdfLink && "Pdf") ||
              (chapter.url && "Url")}
          </strong>
          <div>
            <div className="d-flex justify-content-end">
              <input
                type="text"
                placeholder="Chapter"
                defaultValue={chapter.chapterName || data}
                name="chapterName"
                onBlur={(e) => formDataHandler(e)}
              />

              <Button
                variant="outline-primary"
                onClick={() => modalOpenFunction(item.sectionID)}
              >
                Drip
              </Button>
              <div className="d-flex align-items-center">
                <Button
                  className="w-10 mx-2"
                  variant="outline-light"
                  onClick={() => setIsExpanded1(!isExpanded1)}
                >
                  {!isExpanded1 ? (
                    <i class="fa-solid fa-angle-down"></i>
                  ) : (
                    <i class="fa-solid fa-angle-up"></i>
                  )}
                </Button>
                <i
                  class="fa-solid fa-trash-can"
                  aria-hidden="true"
                  style={{
                    cursor: "pointer",
                    color: "#f44336",
                    fontSize: "25px",
                  }}
                  onClick={() => removeChapterItem(chapter.chapterID)}
                ></i>
              </div>
            </div>
            {isExpanded1 && (
              <div className="border-1">
                <div className="tabbutton-1 mt-5 pt-2" id="tabbutton1">
                  <ul className="d-flex">
                    {list.map((item) => (
                      <ShowActive
                        title={item.title}
                        icon={item.icon}
                        image={item.image}
                        className="tabbutton1"
                        active={selected === item.id}
                        setSelected={setSelected}
                        id={item.id}
                      />
                    ))}
                  </ul>
                  <div className="container">{screen}</div>
                </div>
                <div className="d-flex justify-content-center mb-3"></div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
