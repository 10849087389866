import React from 'react'
import { Button, Modal } from 'react-bootstrap'

function DeleteModal({ showDelete, text, handleDeleteDecision, field, HideDeleteModal, DeletionObject }) {
    const handleChange = (value) => {
        handleDeleteDecision(value)
        HideDeleteModal()
    }
    return (
        <>
            <Modal centered show={showDelete} backdrop="static" onHide={HideDeleteModal} animation={false}>

                <Modal.Body>
                    {
                        !!text ?
                            <h4><strong>{text}</strong></h4>
                            :
                            <h4><strong>Do you want to delete {field} ?</strong></h4>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleChange(true)}>
                        Confirm
                    </Button>
                    <Button variant="danger" onClick={() => handleChange(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal></>
    )
}

export default DeleteModal