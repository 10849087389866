import React, { useEffect } from "react";
import "./CourseCoupon.css";
import {
  FormControl,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  Input,
  Chip,
  InputAdornment,
  FormLabel,
  Select,
  ListItemText,
  OutlinedInput,
} from "@material-ui/core";
import { MenuItem } from "material-ui";
import { MuiThemeProvider } from "material-ui/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  createNewCoupon,
  getCouponsByID,
} from "../../../../services/PostsService";
import { toast, ToastContainer } from "react-toastify";
import { Link, useParams } from "react-router-dom";

export const CourseCoupon = () => {
  const { courses } = useSelector((state) => state.courses);
  const [options, setOptions] = useState([]);
  const params = useParams();
  const [courseName, setCourseName] = useState([]);
  const [input, setInput] = useState({
    couponCode: "",
    numberofAllowed: 0,
    discountType: "",
    couponCurrency: "",
    discountValue: 0,
    validFrom: "",
    validTo: "",
    couponAppliedFor: [],
  });

  const handleChangeCoupon = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const createCoupon = async () => {
    const {
      couponCode,
      numberofAllowed,
      discountType,
      couponCurrency,
      validTo,
      validFrom,
      discountValue,
    } = input;
    const modifyoptions = options.map((option) => option.split("_")[1]);
    console.log({ modifyoptions });
    const data = {
      couponCode: couponCode,
      numberofAllowed: Number(numberofAllowed),
      discountType: discountType,
      couponCurrency: couponCurrency,
      validTo: validTo,
      validFrom: validFrom,
      discountValue: Number(discountValue),
      couponAppliedFor: modifyoptions,
    };
    try {
      const coupon = await createNewCoupon(data);
      toast.success("✔ Coupon created successfully ", {
        position: "top-bottom",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        window.location.replace(`/coupons`);
      }, 2000);
      if (coupon.data.status === 200) {
        setInput({
          couponCode: "",
          numberofAllowed: 0,
          discountType: "",
          couponCurrency: "",
          discountValue: 0,
          validFrom: "",
          validTo: "",
          couponAppliedFor: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (params.id) {
      const fetch = async () => {
        try {
          const data = await getCouponsByID({ id: params.id });
          setInput({
            couponCode: data.data.result.couponCode,
            numberofAllowed: data.data.result.numberofAllowed,
            discountType: data.data.result.discountType,
            couponCurrency: data.data.result.couponCurrency,
            discountValue: data.data.result.discountValue,
            validFrom: data.data.result.validFrom,
            validTo: data.data.result.validTo,
            couponAppliedFor: data.data.result.couponAppliedFor,
            couponCode: data.data.result.couponCode,
            numberofAllowed: data.data.result.numberofAllowed,
          });
          debugger;
        } catch (err) {
          console.log(err);
        }
      };
      fetch();
    }
  }, [params.id]);

  return (
    <MuiThemeProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="card-3">
        <Link to="/coupons">
          <button className="btn btn-danger shadow">
            <i class="fa-solid fa-arrow-left mx-1"></i> Back
          </button>
        </Link>
        <div className="coupon-wrapper">
          <div className="coupon-container">
            <div className="d-flex flex-row  align-items-end coupon-first-row">
              <button type="button" class="coupon-menu-btn">
                {/* <MenuIcon /> */}
                <i class="fa-solid fa-bars"></i>
              </button>
              <h1>Create Course Coupon</h1>
            </div>
            <div className="row">
              <div className="col">
                <FormControl variant="standard" className="coupon-form-input">
                  <InputLabel htmlFor="coupon-input">Coupon Code</InputLabel>
                  <Input
                    onChange={(e) => handleChangeCoupon(e)}
                    name="couponCode"
                    id="coupon-input"
                    value={input.couponCode || ""}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="col">
                <FormControl variant="standard" className="coupon-form-input">
                  <InputLabel htmlFor="coupon-input">
                    Number of use allowed
                  </InputLabel>
                  <Input
                    value={input.numberofAllowed || ""}
                    onChange={(e) => handleChangeCoupon(e)}
                    id="coupon-input"
                    name="numberofAllowed"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-6">
                <FormControl variant="standard" className="coupon-form-input">
                  <InputLabel htmlFor="coupon-input">Discount type</InputLabel>
                  <Select
                    value={input.discountType || ""}
                    name="discountType"
                    onChange={(e) => handleChangeCoupon(e)}
                  >
                    <MenuItem selected value="percentage">
                      percentage
                    </MenuItem>
                    <MenuItem selected value="price">
                      price
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-2">
                <FormControl
                  variant="standard"
                  className="coupon-form-input mt-2"
                >
                  <InputLabel htmlFor="coupon-input">Currency</InputLabel>
                  <Select
                    value={input.couponCurrency || ""}
                    name="couponCurrency"
                    onChange={(e) => handleChangeCoupon(e)}
                  >
                    <MenuItem selected value="USD">
                      USD
                    </MenuItem>
                    <MenuItem selected value="INR">
                      INR
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl variant="standard" className="coupon-form-input">
                  <InputLabel htmlFor="coupon-input">Discount Value</InputLabel>
                  <Input
                    value={input.discountValue || ""}
                    onChange={(e) => handleChangeCoupon(e)}
                    name="discountValue"
                    id="coupon-input"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormControl variant="standard" className="coupon-form-input">
                  <InputLabel htmlFor="coupon-input">
                    Valid from date
                  </InputLabel>
                  <Input
                    onChange={(e) => handleChangeCoupon(e)}
                    type="date"
                    name="validFrom"
                    value={input.validFrom || ""}
                    id="coupon-input"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="col">
                <FormControl variant="standard" className="coupon-form-input">
                  <InputLabel htmlFor="coupon-input">Valid to date</InputLabel>
                  <Input
                    onChange={(e) => handleChangeCoupon(e)}
                    name="validTo"
                    type="date"
                    id="coupon-input"
                    value={input.validTo || ""}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormControl variant="standard" className="coupon-form-input">
                  <FormLabel htmlFor="coupon-input">Select Course</FormLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={input.couponAppliedFor}
                    onChange={(e) => handleChangeCoupon(e)}
                    input={<OutlinedInput label="Tag" />}
                    name="couponAppliedFor"
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {/* {courses.map((course) => course.name)} */}
                    {courses.map((course) => (
                      <MenuItem key={course.course_id} value={course.course_id}>
                        <Checkbox
                          checked={
                            input.couponAppliedFor.indexOf(course.course_id) >
                            -1
                          }
                        />
                        <ListItemText primary={course.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <button
              onClick={createCoupon}
              type="button"
              class="coupon-create-btn"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};
