export const addSection = (data) => {
  return {
    type: "ADD_SECTION",
    payload: {
      data: data,
    },
  };
};
export const removeSection = (data) => {
  return {
    type: "DELETE_SECTION",
    payload: {
      data: data,
    },
  };
};
export const addAssessMent = (data) => {
  return {
    type: "ADD_ASSESSMENT",
    payload: {
      data: data,
    },
  };
};
export const addOption = (data) => {
  return {
    type: "ADD_OPTIONS",
    payload: {
      data: data,
    },
  };
};
export const questionName = (data) => {
  return {
    type: "ADD_QUESTIONS_NAME",
    payload: {
      data: data,
    },
  };
};
export const optionName = (data) => {
  return {
    type: "ADD_OPTION_VALUE",
    payload: {
      data: data,
    },
  };
};
export const hintAction = (data) => {
  return {
    type: "ADD_HINT_VALUE",
    payload: {
      data: data,
    },
  };
};
export const marksAction = (data) => {
  return {
    type: "ADD_MARKS_VALUE",
    payload: {
      data: data,
    },
  };
};
export const optionChecked = (data) => {
  return {
    type: "CORRECT_ANSWER",
    payload: {
      data: data,
    },
  };
};

export const videoOption = (data) => {
  return {
    type: "ADD_VIDEO_OPTION",
    payload: {
      data: data,
    },
  };
};
export const imageOption = (data) => {
  return {
    type: "ADD_IMAGE_OPTION",
    payload: {
      data: data,
    },
  };
};
export const removeOption = (data) => {
  return {
    type: "REMOVE_ASSESSMENT_OPTION",
    payload: {
      data: data,
    },
  };
};
export const removeAssessment = (data) => {
  return {
    type: "REMOVE_ASSESSMENT_LIST",
    payload: {
      data: data,
    },
  };
};
export const passingMarksAction = (data) => {
  return {
    type: "PASSING_MARKS_VALUE",
    payload: {
      data: data,
    },
  };
};
export const numberOfAttempAction = (data) => {
  return {
    type: "NUMBER_OF_ATTEMP_VALUE",
    payload: {
      data: data,
    },
  };
};
export const durationAction = (data) => {
  return {
    type: "DURATION_VALUE",
    payload: {
      data: data,
    },
  };
};
export const addChapterAction = (data) => {
  return {
    type: "ADD_CHAPTER",
    payload: {
      data: data,
    },
  };
};
export const addAllChapter = (data) => {
  return {
    type: "ADD_ALL_CHAPTERS",
    payload: {
      data: data,
    },
  };
};
export const removeChapter = (data) => {
  return {
    type: "REMOVE_CHAPTER",
    payload: {
      data: data,
    },
  };
};
export const addChapterContent = (data) => {
  return {
    type: "ADD_CONTENT",
    payload: {
      data: data,
    },
  };
};
export const clearSection = (data) => {
  return {
    type: "CLEAR_SECTION",
    payload: {
      data: data,
    },
  };
};
export const addVideo = (data) => {
  return {
    type: "ADD_VIDEO",
    payload: {
      data: data,
    },
  };
};
export const pageBuilderAction = (data) => {
  return {
    type: "ADD_HTML",
    payload: {
      data: data,
    },
  };
};
export const addPDF = (data) => {
  return {
    type: "ADD_PDF",
    payload: {
      data: data,
    },
  };
};
export const otherFileLink = (data) => {
  return {
    type: "OTHER_FILE",
    payload: {
      data: data,
    },
  };
};
export const AddSectionName = (data) => {
  return {
    type: "ADD_SECTION_NAME",
    payload: {
      data: data,
    },
  };
};
export const AddActiveDate = (data) => {
  return {
    type: "ADD_ACTIVE_DATE",
    payload: {
      data: data,
    },
  };
};
export const AddDripBy = (data) => {
  return {
    type: "ADD_DRIP_BY",
    payload: {
      data: data,
    },
  };
};
export const AddDripByDate = (data) => {
  return {
    type: "ADD_DRIP_BY_DATE",
    payload: {
      data: data,
    },
  };
};
export const AddActiveDateChapter = (data) => {
  return {
    type: "ADD_ACTIVE_DATE_CHAPTER",
    payload: {
      data: data,
    },
  };
};
export const AddDripByChapter = (data) => {
  return {
    type: "ADD_DRIP_BY_CHAPTER",
    payload: {
      data: data,
    },
  };
};
export const AddDripByDateChapter = (data) => {
  return {
    type: "ADD_DRIP_BY_DATE_CHAPTER",
    payload: {
      data: data,
    },
  };
};

export const expand = (data) => {
  return {
    type: "EXPAND",
    payload: {
      data: data,
    },
  };
};
export const addEmbeddedAction = (data) => {
  return {
    type: "ADD_EMBEDDED_LINK",
    payload: {
      data: data,
    },
  };
};
export const addURLAction = (data) => {
  return {
    type: "ADD_URL_LINK",
    payload: {
      data: data,
    },
  };
};
export const saveAssessmentAction = (data) => {
  return {
    type: "SAVE_ASSESSMENT",
    payload: {
      data: data,
    },
  };
};
export const addEmbeddedLink = (data) => {
  return {
    type: "ADD_EMBEDDED_LINK_IN_CHAPTER",
    payload: {
      data: data,
    },
  };
};
export const addURL = (data) => {
  return {
    type: "ADD_URL_LINK_IN_CHAPTER",
    payload: {
      data: data,
    },
  };
};
export const nextBtn = (data) => {
  return {
    type: "ADD_NEXT_BTN",
    payload: {
      data: data,
    },
  };
};
export const allSectionList = (data) => {
  return {
    type: "ALL_SECTION_LIST",
    payload: {
      data: data,
    },
  };
};
