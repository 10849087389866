import React, { useEffect, useMemo, useRef, useState } from "react";

import ContentBuilder from "../contentbuilder";
// import ContentBuilder from "@innovastudio/contentbuilder";
import { useDispatch, useSelector } from "react-redux";
import { pageBuilderAction } from "../../../../../store/actions/section";

export const PageBuild = () => {
  const { isPageBuilderData, isPageBuilderActive } = useSelector(
    (state) => state.courses
  );

  const dispatch = useDispatch();

  // const builder = new ContentBuilder({
  //   container: ".container",
  //   sidePanel: "left",
  // });

  const builder = new ContentBuilder({
    container: ".container",
    snippetOpen: true,
    sidePanel: "left",
    toolbarAddSnippetButton: true,
    htmlSyntaxHighlighting: true,
    useLightbox: true,
  });

  // let mainCss = builder.mainCss(); // Returns the default typography style for the page.
  // let sectionCss = builder.sectionCss();
  // let mainCss = builder.mainCss();
  // console.log(html, mainCss);

  const html = builder.html();
  const htmlRef = useRef(null);
  const [count, setCount] = useState(0);
  console.log(htmlRef.current);
  const saveHtml = () => {
    const data = {
      chapterID: isPageBuilderData.chapterID,
      sectionID: isPageBuilderData.sectionID,
      courseId: isPageBuilderData.courseId,
      html: html,
    };
    dispatch(pageBuilderAction(data));
  };

  return (
    <>
      <div>
        <button className="btn btn-success" onClick={saveHtml}>
          Save
        </button>
      </div>
      <div ref={htmlRef} class="container mt-2"></div>
    </>
  );
};
