import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
export const UploadForm = ({
  cover,
  setCover,
  video,
  setVideo,
  isImage,
  isPreview,
  isVideo,
  file,
  setFile,
  otherFile,
  pdfFile,
  pdf,
  fileName,
}) => {
  const imageRef = useRef();
  const videoRef = useRef();
  const pdfRef = useRef();
  const fileRef = useRef();
  const [preview, setPreview] = useState();
  const [filePreview, setFilePreview] = useState();
  const [pdfPreview, setPdfPreview] = useState();
  const [Vprev, setVideoprev] = useState();

  useEffect(() => {
    if (!cover) {
      setPreview(undefined);
      return;
    }
    // create the preview
    const objectUrl = URL.createObjectURL(cover);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [cover]);
  useEffect(() => {
    if (!video) {
      setVideoprev(undefined);
      return;
    }
    // create the preview
    const objectUrl = URL.createObjectURL(video);
    setVideoprev(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [video]);
  useEffect(() => {
    if (!file) {
      return;
    }
    // create the preview
    const objectUrl = URL.createObjectURL(file);
    setFilePreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);
  useEffect(() => {
    if (!pdf) {
      return;
    }
    // create the preview
    const objectUrl = URL.createObjectURL(pdf);
    setPdfPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [pdf]);

  return (
    <>
      {isImage && (
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Button className="mb-4" onClick={() => imageRef.current.click()}>
                {cover ? "Replace Image" : "Upload Image"}
              </Button>
              <Form.Control
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                ref={imageRef}
                onChange={(e) => setCover(e.target.files[0])}
              />
            </Form.Group>
            <div style={{ margin: "1em 0em" }}>
              {isPreview && (
                <img
                  src={preview}
                  style={{ height: 200, width: 200 }}
                  onClick={() => imageRef.current.click()}
                  alt="cover"
                />
              )}
            </div>
          </Col>
        </Row>
      )}
      {isVideo && (
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Button className="mb-4" onClick={() => videoRef.current.click()}>
                <i
                  class="fa fa-cloud-upload"
                  style={{ paddingRight: 10 }}
                  aria-hidden="true"
                ></i>
                {video ? "Replace Video" : "Upload Video"}
              </Button>
              <Form.Control
                ref={videoRef}
                style={{ display: "none" }}
                type="file"
                accept="video/*"
                onChange={(e) => setVideo(e.target.files[0])}
              />

              {/* {Vprev && <video width="100%" controls src={Vprev} autoPlay />} */}
            </Form.Group>
          </Col>
        </Row>
      )}
      {otherFile && (
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Button className="mb-4" onClick={() => fileRef.current.click()}>
                <i
                  class="fa fa-download pl-2"
                  style={{ paddingRight: 10 }}
                  aria-hidden="true"
                ></i>
                {file ? "Replace file" : fileName}
              </Button>
              <Form.Control
                ref={fileRef}
                style={{ display: "none" }}
                type="file"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf, image/*"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {pdfFile && (
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Button className="mb-4" onClick={() => pdfRef.current.click()}>
                {pdf ? "Replace file" : "Add Downloadable File"}
              </Button>
              <Form.Control
                ref={pdfRef}
                style={{ display: "none" }}
                type="file"
                accept="application/pdf, image/*"
                onChange={(e) => setFile(e.target.files[0])}
              />
              {pdfPreview && <iframe width="100%" src={pdfPreview} />}
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
};
