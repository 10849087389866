import React from "react";

import "./Session.css";
import { useDispatch, useSelector } from "react-redux";
import { addLiveSession } from "../../../../../../store/actions/liveCourse";
import ScheduleItem from "./ScheduleItem";

export const MultipleSession = () => {
  const { livesession, singleSessionList, multipleSessionList } = useSelector(
    (state) => state.livesession
  );
  const id = window.location.pathname.split("/")[3];
  const dispatch = useDispatch();

  const signleSession = window.location.pathname.split("/")[4];
  const AddSession = () => {
    const data = {
      image: "",
      topic: "",
      Startdate: "",
      start_time: "",
      endDate: "",
      endTime: "",
      link: "",
      id: Math.random(10000000).toString(29),
    };
    dispatch(addLiveSession(data));
  };
  return (
    <div>
      {signleSession === "single-session" ? (
        <ScheduleItem
          timeZone={true}
          singleSessionList={singleSessionList.filter(
            (item) => item.courseId === id
          )}
        />
      ) : (
        <ScheduleItem item={multipleSessionList} />
      )}
      {!signleSession === "single-session" && (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-success"
            disabled={signleSession === "single-session"}
            onClick={AddSession}
          >
            Add Session +
          </button>
        </div>
      )}
    </div>
  );
};
