import React, { useEffect, useState } from 'react'
import Loader from '../Common/Loader/Loader'
import CommonTable from '../Common/Table/CommonTable'
import { exportToolsUser, getToolsUserForAdmin, getToolsUsers, updateAiCredits } from '../services/apis'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { saveAs } from "file-saver"
import { MdOutlineEdit } from "react-icons/md";
import { Form } from 'react-bootstrap';
import { useQuery } from "react-query";
import { IoRefreshCircleOutline } from "react-icons/io5";
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { unparse } from 'papaparse';

function ToolsUser() {
    const [ToolsData, setToolsData] = useState([])
    const [loading, setloading] = useState(false)
    const [show, setShow] = useState(false);
    const collegeId = localStorage.getItem('userId')
    const navigate = useHistory()
    const [ResumeData, setResumeData] = useState()
    const [showResumeInModal, setshowResumeInModal] = useState(false)
    const [aiCredits, setaiCredits] = useState({
        _id: "",
        aicredits: ""
    })
    const [creditLoading, setcreditLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchQuery, setsearchQuery] = useState('')
    const [initialLoad, setinitialLoad] = useState(true);
    // modal
    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const fetchStudents = async (searchQuery = '', currentPage = 1, perPage = 10) => {
        try {
            // if (initialLoad) {
            //     setloading(true)
            // }
            const ROLE = localStorage.getItem("role")
            if (ROLE === "ADMIN") {
                let query

                if (window.location.pathname.includes('resumatic')) {
                    query = {
                        params: { perPage, currentPage, searchQuery, tool: "resumatic" },
                    }
                } else {
                    query = {
                        params: { perPage, currentPage, searchQuery, tool: "interview-pro" },
                    }
                }

                const res = await getToolsUserForAdmin(query)
                console.log(res)
                setTotalRows(res.data.result.totalUsers)
                setToolsData(res.data.result.data)
                // if (initialLoad) {
                //     setloading(false)
                //     setinitialLoad(false)
                // }
                return res.data.result

            } else {
                const res = await getToolsUsers(collegeId)
                if (window.location.pathname.includes('resumatic')) {

                    setToolsData(res.data.tools.resumatic)
                } else {

                    setToolsData(res.data.tools.interviewPro)
                }
            }
            setloading(false)
        } catch (error) {
            return error
            setloading(false)
            console.log(error)
        }
    }

    // useEffect(() => {
    //     fetchStudents()
    // }, [])

    const debouncedLoadData = useCallback(debounce(fetchStudents, 300), []);


    useEffect(() => {
        debouncedLoadData(searchQuery, currentPage, perPage);
    }, [searchQuery, currentPage, perPage, debouncedLoadData]);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };



    const handleAicreditSubmit = async () => {
        try {
            const res = await updateAiCredits(aiCredits)
            console.log(res)
            setShow(false)
            fetchStudents()
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 2
        },
        // {
        //     name: 'Students',
        //     selector: row => <div>
        //         <button className='common-btn p-2 rounded-2' onClick={() => {
        //             setallStudents(row.students)
        //             ShowStudentsModal()
        //         }}>View ({row.students.length})</button>
        //     </div>,
        //     sortable: true
        // },

    ]




    // const [studentsModal, setStudentsModal] = useState(false);
    // const [allStudents, setallStudents] = useState([])
    // const ShowStudentsModal = () => setStudentsModal(true);
    // const CloseStudentsModal = () => setStudentsModal(false);
    const ROLE = localStorage.getItem('role')
    const ToolUserColumn = [{
        name: 'S.No',
        selector: (row, index) => index + 1,
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        grow: 2
    },
    {
        name: 'Phone',
        selector: row => row.phoneNumber,
        sortable: true
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true
    },
    (ROLE === "ADMIN" && window.location.pathname.includes('/resumatic')) && {
        name: 'Ai Credits',
        selector: row => <div>{row.aicredits} <button onClick={() => {
            setshowResumeInModal(false)
            setaiCredits({
                _id: row._id,
                aicredits: row.aicredits
            })
            handleShow()
        }} className='common-btn px-2 p-1 rounded-2 mx-2'><MdOutlineEdit fontSize={24} /></button></div>,
        sortable: true
    }
        ,


    {
        name: 'Action',
        selector: row => {
            return !!window.location.pathname.includes('/resumatic') ?
                row?.Resume?.length === 0 ? "No Resume" : <button className='common-btn p-3 rounded-2 cursor-pointer' onClick={() => {
                    setResumeData(row.Resume)
                    handleShow()
                    setshowResumeInModal(true)
                }} > View Resumes</button> : <div onClick={() => navigate.push(`/college/interview-pro/users/${row._id}/interviews`)
                } className='common-btn p-3 rounded-2 cursor-pointer' >
                    View Interviews
                </div>
        }
        ,
        sortable: true
    },
    ]
    const resumeColumn = [{
        name: 'S.No',
        selector: (row, index) => index + 1,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row, index) => `Resume ${index + 1}`,
        sortable: true,
        grow: 2
    },
    {
        name: 'Action',
        selector: (row, index) => <button className='common-btn px-2 p-3 rounded' onClick={() => downloadResume(row, row.template, index + 1)} >Download  {
            (Loading?.status && index === Loading?.index - 1) && <span className='ms-2'>
                <Loader color="white" size="sm" />
            </span>
        }
        </button>,
        sortable: true,
        grow: 2
    }

    ]




    const [Loading, setLoading] = useState(false)

    const downloadResume = async (resumeData, templateId, index) => {
        try {
            setLoading({
                status: true,
                index
            });

            const pdfResponse = await fetch(
                "https://api.viosa.in/resumatic/pdf/generate-pdf",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ResumeData: resumeData,
                        Template: templateId,
                        doctype: "resume",
                        //                     CoverData: CoverData,
                    }),
                }
            );
            if (pdfResponse.ok) {
                const { pdfUrl } = await pdfResponse.json();
                const pdfurl2 = "https://api.viosa.in" + pdfUrl;
                saveAs(`https://api.viosa.in${pdfUrl}`, `Resume ${index}.pdf`);
            } else {
                const errorMessage = await pdfResponse.text();
                console.error("Failed to generate PDF,", errorMessage);
            }
        } catch (error) {
            console.error("Error during PDF generation:", error);
        } finally {
            setLoading({
                status: false,
                index
            });
        }
    };


    const handleAIChange = (e) => {
        setaiCredits({ ...aiCredits, aicredits: e.target.value })
    }



    // const csvData = ToolsData?.map((row, index) => ({
    //     "S No.": index + 1,
    //     "Name": row?.name,
    //     "Phone": row.phoneNumber,
    //     "Email": row?.email,
    // }));



    const [FilteredData, setFilteredData] = useState([])
    useEffect(() => {
        handleSearchChange()
    }, [searchQuery, ToolsData]);

    const handleSearchChange = async (e) => {
        if (searchQuery === '') {
            setFilteredData(ToolsData);
        } else {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filteredResults = ToolsData.filter(item =>
                Object.values(item).some(
                    val => val && val.toString().toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filteredResults);
        }
    }


    // resumeData

    const csvResumeData = ResumeData?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row?.name,
    }));


    const [searchResumeQuery, setsearchResumeQuery] = useState('')

    const [FilteredResumeData, setFilteredResumeData] = useState([])
    useEffect(() => {
        handleSearchResumeChange()
    }, [searchResumeQuery, ResumeData]);

    const handleSearchResumeChange = async (e) => {
        if (searchResumeQuery === '') {
            setFilteredResumeData(ResumeData);
        } else {
            const lowercasedQuery = searchResumeQuery.toLowerCase();
            const filteredResults = ResumeData.filter(item =>
                Object.values(item).some(
                    val => val && val.toString().toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredResumeData(filteredResults);
        }
    }



    //fetch batches to export

    const [fetchingCSVdataLoading, setfetchingCSVdataLoading] = useState(false)
    const [csvData, setcsvData] = useState([])

    const fetchUserToExport = async () => {
        try {
            setfetchingCSVdataLoading(true)
            const query = {
                params: {
                    searchQuery,
                    tool: !!window.location.pathname.includes('/resumatic') ? "resumatic" : "interview-pro",
                    collegeId

                },
            }
            const response = await exportToolsUser(query)
            setcsvData(response?.data?.result?.data)
            console.log(response)
            const formattedData = response?.data?.result?.data?.map(item => ({
                "Name": item?.name,
                "Phone": item.phoneNumber,
                "Email": item.email,
            }));

            const csv = unparse(formattedData);

            setfetchingCSVdataLoading(false)
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `UserData.csv`);
        } catch (error) {
            setfetchingCSVdataLoading(false)
            console.log(error)
        }
    }
    return (
        <>

            <div className='mb-3'>
                <div className="d-flex w-100 gap-4 mb-3  align-items-center">
                    {/* <input type="search" placeholder='Search User' onChange={(e) => setsearchQuery(e.target.value)} className='p-3 rounded-4 w-25' /> */}
                </div>
                <div className='d-flex gap-3 '>

                    {
                        window.location.pathname.includes('/resumatic') ?
                            <h3>Resumatic</h3> : <h3>Interview Pro</h3>
                    }
                    {/* <IoRefreshCircleOutline fontSize={28} /> */}
                </div>
                {/* <button></button> */}
            </div>
            {
                loading ? <Loader /> : <CommonTable csvData={csvData} csvName={"Tool-Users-Data"} data={ToolsData}
                    columns={ToolUserColumn} setsearchQuery={setsearchQuery}
                    tableType="user"
                    fetchUserToExport={fetchUserToExport}
                    fetchingCSVdataLoading={fetchingCSVdataLoading}
                    totalRows={totalRows}
                    handlePageChange={handlePageChange}
                    handlePerRowsChange={handlePerRowsChange}
                />
            }


            <Modal show={show} onHide={handleClose} centered>

                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            showResumeInModal ? "Resumes" : "Ai Credits"
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        showResumeInModal ? <CommonTable csvData={csvResumeData} csvName={"Resumatic-Resume"} data={FilteredResumeData}
                            columns={resumeColumn} setsearchQuery={setsearchResumeQuery} /> : <>
                            <Form.Group className="w-100 mt-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Credits</Form.Label>
                                <Form.Control onChange={handleAIChange} type="number" value={aiCredits?.aicredits} className='border border-dark' placeholder="credits" />
                            </Form.Group>
                            <div className='d-flex justify-content-end mt-3'>
                                <button className='common-btn p-2 px-3 rounded-2' onClick={handleAicreditSubmit}>Update Credits</button>
                            </div>
                        </>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>



        </>
    )
}

export default ToolsUser