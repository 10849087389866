import React, { useState, useEffect, useMemo } from "react";
// import PageTitle from "../../../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { getOrders } from "../../../../services/PostsService";
import RadialDount from "../../Dashboard/Dashboard/RadialDount";
import exportFromJSON from "export-from-json";

import { REVENUECOLUMNS } from "../FilteringTable/Columns";
import { GlobalFilter } from "../FilteringTable/GlobalFilter";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/core";
import CommonTable from "../../../../Common/Table/CommonTable";
import moment from "moment";

export const Revenue = () => {
  const [orders, setOrders] = useState([]);
  const columns = useMemo(() => REVENUECOLUMNS, []);
  const data = orders;
  const fetch = async () => {
    const response = await getOrders();
    setOrders(response.data.result);
  };
  useEffect(() => {
    fetch();
  }, []);

  const downloadOrders = () => {
    const fileName = "orders";
    const exportType = exportFromJSON.types.csv;
    // const  = orders;
    const data = orders.map((item, key) => {
      return {
        id: item?.userId?._id || "",
        Date: new Date(item.createdAt).toDateString(),
        time: new Date(item.updatedAt).toLocaleTimeString(),
        Name: item?.userId?.name,
        Email: item?.userId?.email,
        "Course Name": item.courseName,
        status: item?.status,
        RazorpayId: item.razorpay_payment_id,
        OrderId: item.razorpay_order_id,
        Currency: item.currency,
        Amount: item.amount,
        CourseId: item.courseId[0],
      };
    });

    if (data.length === 0) return;
    exportFromJSON({ data, fileName, exportType });
  };

  // const tableInstance = useTable(
  //   {
  //     columns,
  //     data,
  //     initialState: { pageIndex: 0 },
  //   },
  //   useFilters,
  //   useGlobalFilter,
  //   usePagination
  // );
  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   prepareRow,
  //   state,
  //   page,
  //   gotoPage,
  //   pageCount,
  //   pageOptions,
  //   nextPage,
  //   previousPage,
  //   canNextPage,
  //   canPreviousPage,
  //   setGlobalFilter,
  // } = tableInstance;

  // const { globalFilter, pageIndex } = state;

  const totalEarning = orders?.reduce((sum, item) => {
    return sum + item.amount;
  }, 0);

  var now = new Date().getDate();
  let yesterday = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000);
  let last7Days = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  var datas = orders?.filter(
    (p) => Number(p.createdAt.split("T")[0].split("-")[2]) === now
  );
  const [status, setStatus] = useState("");

  // const dayEaring = datas.reduce((sum, item) => {
  //   return sum + item.amount;
  // }, 0);
  const dayEaring = useMemo(
    () =>
      orders
        .filter(
          (p) =>
            new Date(p.createdAt).toDateString() === new Date().toDateString()
        )
        .reduce((sum, item) => sum + item.amount, 0),
    [orders]
  );
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [option, setOption] = useState("");
  const orderFilterByDate = async (e) => {
    let date = e.target.value;
    setOption(date);
    if (date === "Today") {
      const orderData = await getOrders("filterByDate=today");
      setOrders(orderData?.data?.result);
    }
    if (date === "Yesterday") {
      const orderData = await getOrders("filterByDate=yesterday");
      debugger;
      setOrders(orderData?.data?.result);
    }
    if (date === "week") {
      const orderData = await getOrders("filterByDate=week");
      setOrders(orderData.data?.result);
    }
    if (date === "month") {
      const orderData = await getOrders("filterByDate=month");
      setOrders(orderData.data?.result);
    }
    if (date === "3month") {
      const orderData = await getOrders("filterByDate=3month");
      setOrders(orderData.data?.result);
    }
    if (date === "year") {
      const orderData = await getOrders("filterByDate=year");
      setOrders(orderData.data?.result);
    }
  };
  const orderFilterByStatus = async (e) => {
    let status = e.target.value;
    setStatus(status);
    if (status === "SUCCESS") {
      const orderData = await getOrders("filterByStatus=SUCCESS");
      setOrders(orderData.data?.result);
    }
    if (status === "FAILED") {
      const orderData = await getOrders("filterByStatus=FAILED");
      setOrders(orderData.data?.result);
    }
    if (status === "INITIATED") {
      const orderData = await getOrders("filterByStatus=INITIATED");
      setOrders(orderData.data?.result);
    }
  };
  useEffect(() => {
    if (option === "range" && from && to) {
      const fetchOrders = async () => {
        const orderData = await getOrders(
          `filterByDate=range&from=${from}&to=${to}`
        );
        setOrders(orderData.data?.result);
      };
      fetchOrders();
    }
  }, [from, to]);




  const csvData = orders?.map((row, index) => ({
    "S No.": index + 1,
    "Name": row?.userId?.name,
    "Course Name": row.courseName,
    "Payment Id": row.razorpay_payment_id || row.orderId,
    "Email": row?.userId?.email,
    "Purchase Date": moment(row.createdAt).format("DD-MM-YYYY"),
    "Amount": row?.amount,
    "Status": row.status
  }));


  const [searchQuery, setsearchQuery] = useState('')

  const [FilteredData, setFilteredData] = useState([])
  useEffect(() => {
    handleSearchChange()
  }, [searchQuery, orders]);

  const handleSearchChange = async (e) => {
    if (searchQuery === '') {
      setFilteredData(orders);
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filteredResults = orders.filter(item =>
        Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredData(filteredResults);
    }
  }

  return (
    <div className="card-3">
      <div className="d-flex">
        <div className="card-body card-2 mx-2">
          <div id="radialChart" className="radialChart">
            <RadialDount />
          </div>
          <h2>Rs. {Math.round(totalEarning)}</h2>
          <span className="fs-16 text-black">Total Generated Revenue  </span>
        </div>
        <div className="card-body card-2 mx-2">
          <div id="radialChart" className="radialChart">
            <RadialDount />
          </div>
          <h2>{Math.round(dayEaring)}</h2>
          <span className="fs-16 text-black">Revenue Generated for Today</span>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-5 mb-3">
        <select
          onChange={(e) => orderFilterByDate(e)}
          className="form-control w-25"
          value={option}
          style={{ border: "2px solid #52144d", color: "#000" }}
        >
          <option hidden selected>
            Filter By Date
          </option>
          <option value="Today">Today</option>
          <option value="Yesterday">Yesterday</option>
          <option value="week">Last 7 Days</option>
          <option value="month">Last 30 Days</option>
          <option value="3month">Last 90 Days</option>
          <option value="year">Last 365 Days</option>
          <option value="range">Custom</option>
        </select>
        <select
          onChange={(e) => orderFilterByStatus(e)}
          className="form-control w-25"
          style={{ border: "2px solid #52144d", color: "#000" }}
        >
          <option hidden selected>
            Filter By Status
          </option>
          <option value="SUCCESS">SUCCESS</option>
          <option value="INITIATED">INITIATED</option>
          <option value="FAILED">FAILED</option>
        </select>
        {option === "range" && (
          <div className="d-flex">
            <input
              type="date"
              placeholder="From"
              onChange={(e) => setFrom(e.target.value)}
              className="form-control mx-2"
            />
            <input
              onChange={(e) => setTo(e.target.value)}
              type="date"
              placeholder="To"
              className="form-control"
            />
          </div>
        )}

        <button className="btn btn-primary" onClick={downloadOrders}>
          <i class="fa-solid fa-file-invoice-dollar mx-2"></i> Export Orders
        </button>
      </div>
      <div className="my-5">
        <CommonTable columns={REVENUECOLUMNS} csvData={csvData} csvName={"Revenue-Data"} data={FilteredData} setsearchQuery={setsearchQuery} />
      </div>
      {/* {(option?.length > 0 || status) && (
        <div className="d-flex justify-content-between mt-1 mb-3">
          <button
            className="btn btn-secondary"
            onClick={() => {
              setOption("");
              fetch();
            }}
          >
            Reset Filter
          </button>
        </div>
      )}
      <div className="d-flex justify-content-end mt-5 mb-3"></div>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <table {...getTableProps()} className="table  display border-1">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ background: "#52144D", color: "#fff" }}
                >
                  {column.render("Header")}
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="">
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="active-hover active-hover-fw-bold cursor-pointer"
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table> */}
      {/* <div className="d-flex justify-content-between">
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
          {""}
        </span>
        <span className="table-index">
          Go to page :{" "}
          <input
            type="number"
            className="ml-2"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
          />
        </span>
      </div>
      <div className="text-center">
        <div className="filter-pagination  mt-3">
          <button
            className=" previous-button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>

          <button
            className="previous-button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <button
            className="next-button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <button
            className=" next-button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
      </div> */}
    </div>
  );
};
