import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Course from "../../../../utils/Course";

const OngoingSession = () => {
  const { multipleSessionList } = useSelector((state) => state.livesession);
  const cId = window.location.pathname.split("/")[3];
  const history = useHistory();
  const [redirect, setRedict] = useState(false);

  useEffect(() => {
    if (redirect) {
      let interval = setInterval(() => {
        history.push("/course/" + cId);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [redirect]);

  const saveMeeting = () => {
    if (window.confirm("Are you sure you want to save")) {
      multipleSessionList.map((session) =>
        Course.zoomMeeting(
          (resp, err) => {
            if (err)
              toast.error(err.message, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            const data = {
              id: session.id,
              courseId: cId,
              data: {
                name: session.name,
                type: session.sessionName,
                Startdate: session.Startdate,
                start_time: session.start_time,
                start_url: resp.response.start_url,
                join_url: resp.response.join_url,
                end_time: session.end_time,
                image: session.image,
              },
              start_url: resp.response.start_url,
              join_url: resp.response.join_url,
            };
            if (resp.response.start_url && resp.response.join_url) {
              Course.liveSessionApi((resp, err) => {
                if (err)
                  toast.error(err.message, {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                // dispatch(saveScheduleSingle(data));
              }, data);
            }
          },
          {
            id: session.id,
            courseId: cId,
            data: {
              name: session.name,
              Startdate: session.Startdate,
              start_time: session.start_time,
              end_time: session.end_time,
            },
          }
        )
      );
      toast.success("Session Created ....", {
        position: "bottom-center",
      });
      setRedict(true);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div class="overflow-x-auto">
        <table class="table w-full">
          <thead>
            <tr>
              <th>Title</th>
              <th>Date</th>
              <th>Start Time</th>
              <th>End Time</th>
            </tr>
          </thead>
          <tbody>
            {multipleSessionList?.map((session) => (
              <tr>
                <th>{session.name}</th>
                <td>{session.Startdate}</td>
                <td>{session.start_time}</td>
                <td>{session.endTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-end flex-end">
          <button className="btn btn-secondary">Previous</button>
        </div>

        <div className="d-flex justify-content-end flex-end">
          <button className="btn btn-success" onClick={saveMeeting}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default OngoingSession;
