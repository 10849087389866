import React, { useEffect, useState } from 'react'
import { CreateSkillTypes, deleteSkillAPI, deleteSkillType, deleteSkillTypeAPI, getAllkillTypes } from '../../services/apis'
import CommonTable from '../../Common/Table/CommonTable'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import { Form, Modal } from 'react-bootstrap'
import Loader from '../../Common/Loader/Loader'
import { toast } from 'react-toastify'
import DeleteModal from '../../Common/Components/DeleteModal'

function InterviewSkillsType() {
    const [SkillTypeData, setSkillTypeData] = useState([])
    const [selectedSkillType, setselectedSkillType] = useState({})
    const [loadingCreation, setloadingCreation] = useState(false)
    const [show, setShow] = useState(false);
    const [editStatus, seteditStatus] = useState(false)
    const [SkillTypeForm, setSkillTypeForm] = useState({
        skills: [],
        skilltype: ""
    })
    const handleClose = () => {
        setShow(false)
        setSkillTypeForm({ skilltype: "" })
        fetchSkillsType()
    };
    const handleShow = () => setShow(true);
    const fetchSkillsType = async () => {
        try {
            const res = await getAllkillTypes()
            console.log(res)
            setSkillTypeData(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchSkillsType()
    }, [])


    const [showSkillsModal, setshowSkillsModal] = useState(false)
    const handleShowSkillsModal = () => {
        setshowSkillsModal(true)
    }

    const handleCloseSkillsModal = () => {
        setshowSkillsModal(false)
    }

    const columnsForSkillType = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Skill Type Name',
            selector: row => row.skilltype,
            sortable: true,
            grow: 2
        },
        {
            name: 'No. of Skills',
            selector: row => <button className='common-btn p-2 px-3 rounded-2' onClick={() => {
                handleShowSkillsModal()
                setselectedSkillType(row)
            }}>View {row.skills.length}</button>,
            sortable: true,
            grow: 2
        },

        {
            name: 'Action',
            selector: row => <div className="d-flex gap-3">
                <button onClick={() => {
                    // setcorporateForm(row)
                    // setisEdit(true)
                    seteditStatus(true)
                    setSkillTypeForm(row)
                    handleShow()
                }} className="p-2 border rounded-2 border-dark cursor-pointer"><MdModeEdit fontSize={24} style={{ color: "blue" }} /> </button>

                <span className="p-2 border rounded-2 border-dark cursor-pointer" onClick={() => {
                }} ><MdDelete onClick={() => {
                    handleshowDelete()
                    setdeleteSKillTypeData(row)
                    // setDeleteData(row)
                    // handleCollegeDelete(row._id)
                }} fontSize={24} style={{ color: "red" }} /></span>
            </div>,
        },
    ]

    const columnsForSkills = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Skill  Name',
            selector: row => row.skills,
            sortable: true,
            grow: 2
        }
    ]
    const handleChange = (e) => {
        const value = e.target.value;
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setSkillTypeForm({ ...SkillTypeForm, skilltype: capitalizedValue });
    };
    const handleSubmit = async (e) => {
        if (!SkillTypeForm.skilltype) {
            toast.error("Please Write Skill Type")
            return;
        }
        e.preventDefault()
        try {
            setloadingCreation(true)
            const payload = {
                skill: SkillTypeForm?.skills,
                skilltype: SkillTypeForm?.skilltype
            }
            const res = await CreateSkillTypes(payload)
            toast.success("Skill Type Created")
            handleClose()
            setSkillTypeForm({
                skilltype: "",
                skills: []
            })
            setloadingCreation(false)
        } catch (error) {
            setloadingCreation(false)
            console.log(error)
        }
    }
    const handleUpdate = async (e) => {
        if (!SkillTypeForm.skilltype) {
            toast.error("Please Write Skill Type")
            return;
        }
        e.preventDefault()
        try {
            setloadingCreation(true)
            const payload = {
                skill: SkillTypeForm?.skills,
                skilltype: SkillTypeForm?.skilltype
            }
            const res = await CreateSkillTypes(payload)
            toast.success("Skill Type Created")
            handleClose()
            setSkillTypeForm({
                skilltype: "",
                skills: []
            })
            setloadingCreation(false)
        } catch (error) {
            setloadingCreation(false)
            console.log(error)
        }
    }
    // skills 
    const [skillInput, setskillInput] = useState(null)
    const handleSkillAdd = () => {
        if (!!skillInput) {
            if (!SkillTypeForm?.skilltype) {
                toast.error("Please fill skill type")
                return
            }
            const payload = {
                skills: skillInput,
                skilltype: SkillTypeForm?.skilltype
            }
            const newData = [...SkillTypeForm?.skills, payload]
            // const
            setskillInput("")
            setSkillTypeForm({ ...SkillTypeForm, skills: newData })
            toast.success("Skill Added")
        } else {
            toast.error("Please write a skill")
        }
    }
    const handleRemoveSkill = (item) => {
        // e.preventDefault()
        const newData = SkillTypeForm?.skills?.filter(skill => skill.skills !== item)
        toast.success(`${item} is removed from skills`)
        setSkillTypeForm({ ...SkillTypeForm, skills: newData })

    }

    const handleDeleteSkillType = async (id) => {
        try {
            await deleteSkillTypeAPI(id)
            toast.success("Skilltype Deleted")
            fetchSkillsType()
        } catch (error) {
            console.log(error)
        }
    }

    // delete Skill type modal
    const [showDelete, setshowDelete] = useState(false)
    const [deleteSKillTypeData, setdeleteSKillTypeData] = useState(null)
    const handleshowDelete = () => {
        setshowDelete(true)
    }
    const HideDeleteModal = () => {
        setshowDelete(false)
    }
    const handleDeleteDecision = (value) => {
        if (value) {
            handleDeleteSkillType(deleteSKillTypeData._id)
            // console.log("delte")
        } else {
            toast.error("No Skilltype Deleted")
            // console.log("delte")
        }
    }


    // delete Skill modal
    const handleDeleteSkill = async (e, id) => {
        try {
            const payload = {
                skillTypeId: id,
                skillId: deleteSKillData._id
            }
            handleRemoveSkill(id)
            await deleteSkillAPI(payload)
            toast.success("Skilltype Deleted")
            fetchSkillsType()
        } catch (error) {
            console.log(error)
        }
    }
    const [showDeleteSkillModal, setshowDeleteSkillModal] = useState(false)
    const [deleteSKillData, setdeleteSKillData] = useState(null)
    const handleshowDeleteSkill = () => {
        setshowDeleteSkillModal(true)
    }
    const HideDeleteSkillModal = () => {
        setshowDeleteSkillModal(false)
    }
    const handleSkillDeleteDecision = (value) => {
        if (value) {

            handleDeleteSkill(SkillTypeForm?._id)

        } else {
            toast.error("No Skill Deleted")
            // console.log("delte")
        }
    }


    // skilltype

    
  const csvData = SkillTypeData?.map((row, index) => ({
    "S No.": index + 1,
    "Skill Type Name": row.skilltype,
    "No. Of Skills":row.skills.length
}));


const [searchQuery, setsearchQuery] = useState('')

const [FilteredData, setFilteredData] = useState([])
useEffect(() => {
    handleSearchChange()
}, [searchQuery, SkillTypeData]);

const handleSearchChange = async (e) => {
    if (searchQuery === '') {
        setFilteredData(SkillTypeData);
    } else {
        const lowercasedQuery = searchQuery.toLowerCase();
        const filteredResults = SkillTypeData.filter(item =>
            Object.values(item).some(
                val => val && val.toString().toLowerCase().includes(lowercasedQuery)
            )
        );
        setFilteredData(filteredResults);
    }
}

// skill

    
const csvSkillData = selectedSkillType?.skills?.map((row, index) => ({
    "S No.": index + 1,
    "Skill Type Name": row.skilltype,
    "No. Of Skills":row.skills.length
}));


const [searchSKillQuery, setsearchSkillQuery] = useState('')

const [FilteredSkillData, setFilteredSkillData] = useState([])
useEffect(() => {
    handleSearchSkillChange()
}, [searchSKillQuery, selectedSkillType?.skills]);

const handleSearchSkillChange = async (e) => {
    if (searchSKillQuery === '') {
        setFilteredSkillData(selectedSkillType?.skills);
    } else {
        const lowercasedQuery = searchSKillQuery?.toLowerCase();
        const filteredResults = selectedSkillType?.skills.filter(item =>
            Object.values(item).some(
                val => val && val.toString().toLowerCase().includes(lowercasedQuery)
            )
        );
        setFilteredSkillData(filteredResults);
    }
}
    return (
        <section>
            <div className="d-flex w-100 justify-content-end align-items-end">

                <button onClick={() => {
                    handleShow()
                    // setisEdit(false)
                }} className="p-3 rounded mb-4 text-white px-4 " style={{ background: "#fea302" }}>Create Skill Type</button>
            </div>
            <CommonTable
                data={FilteredData}
                columns={columnsForSkillType}
                csvData={csvData}
                csvName={"Skill-Type-Data"}
                setsearchQuery={setsearchQuery}
            />

            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Skill Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label> Skill Type</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={SkillTypeForm?.skilltype} name="skilltype" className='border border-dark' placeholder="Write Skill Type" />
                        </Form.Group>

                    </Form>
                    <div>
                        <Form.Label> Add Skill </Form.Label>
                        <div className="mb-3 border border-dark rounded-2 p-3 " controlId="exampleForm.ControlInput1 ">

                            <div className='gap-3 d-flex select-heading flex-wrap w-100 mb-2'>
                                {SkillTypeForm?.skills?.length > 0 && SkillTypeForm?.skills?.map((item, index) => {
                                    return <div className='labelButton'>
                                        <h5 className='mb-0'>
                                            {item.skills}
                                        </h5>
                                        <button className='rounded-2   p-1' onClick={(e) => {
                                            handleshowDeleteSkill()
                                            setdeleteSKillData(item)

                                        }}>X</button>
                                    </div>
                                })}</div>
                            <Form.Group className="mb-0 " controlId="exampleForm.ControlInput1">
                                <Form.Label> Skill name</Form.Label>
                                <Form.Control type="text" onChange={(e) => setskillInput(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))} value={skillInput} name="skillInput" className='border border-dark' placeholder="Write Skill " />
                                <div className='d-flex justify-content-end mt-2 '>
                                    <button className='common-btn p-2 px-4 rounded-2' onClick={handleSkillAdd}>Add
                                    </button>
                                </div>

                            </Form.Group>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                        {
                            editStatus ?
                                <button className='common-btn p-2 rounded-2 px-4' onClick={handleUpdate}>Update
                                    {
                                        loadingCreation && <Loader />
                                    }

                                </button> :
                                <button className='common-btn p-2 rounded-2 px-4' onClick={handleSubmit}>Submit
                                    {
                                        loadingCreation && <Loader />
                                    }

                                </button>
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showSkillsModal} onHide={handleCloseSkillsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>All Skills</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonTable
                        data={FilteredSkillData}
                        columns={columnsForSkills}
                        csvData={csvSkillData}
                        csvName={"Skill-Data"}
                        setsearchQuery={setsearchSkillQuery}
                    />
                </Modal.Body>
            </Modal>
            <DeleteModal handleDeleteDecision={handleDeleteDecision} text={`Do you want to delete ${deleteSKillTypeData?.skilltype}`} showDelete={showDelete} HideDeleteModal={HideDeleteModal} />

            <DeleteModal handleDeleteDecision={handleSkillDeleteDecision} text={`Do you want to delete ${deleteSKillTypeData?.skilltype}`} showDelete={showDeleteSkillModal} HideDeleteModal={HideDeleteSkillModal} />

        </section>
    )
}

export default InterviewSkillsType