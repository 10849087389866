import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ShowActive } from "../../PluginsMenu/Nestable/utils";
import { Show } from "../../show";
import PageBuilderPreview from "./Chapter/PageBuilderPreview";
import Curriculam from "./Curriculam";
import LandingPage from "./LandingPage";
import Publish from "./Publish";

export const CourseMain = ({ next }) => {
  const [selected, setSelected] = useState(1);
  const { isPageBuilderActive } = useSelector((state) => state.courses);

  const list = [
    {
      id: 1,
      title: "Curriculum",
    },
    {
      id: 2,
      title: "Publish",
    }
    // {
    //   id: 3,
    //   title: "Landing Page",
    // },
  ];

  const getRendered = (state) => {
    const Screen = {
      1: <Curriculam />,
      2: <Publish />,
      3: <LandingPage />,
    };
    if (!Screen[state]) return null;
    return Screen[state];
  };

  return (
    <>
      <Show condition={!isPageBuilderActive}>
        <div>
          <button className="btn btn-danger">
            <Link to="/course" className="white-100">
              <i class="fa-solid fa-arrow-left mx-1"></i> Back
            </Link>
          </button>
        </div>
        <div className="card-3 mt-4">
          <div className="tabbutton mt-5 pt-2" id="tabbutton">
            <ul className="d-flex justify-content-evenly">
              {list.map((item) => (
                <ShowActive
                  title={item.title}
                  className="tabbutton"
                  active={selected === item.id}
                  setSelected={setSelected}
                  id={item.id}
                />
              ))}
            </ul>
            <div className="container">{getRendered(selected)}</div>
            <div className="d-flex justify-content-center">
              {selected > 1 && (
                <Button
                  className="mt-3 mx-2"
                  variant="danger"
                  onClick={() =>
                    setSelected(selected !== 1 ? selected - 1 : selected)
                  }
                >
                  &larr; Prev
                </Button>
              )}
              <Button
                className="mt-3"
                variant="danger"
                onClick={() =>
                  setSelected(selected !== 3 ? selected + 1 : selected)
                }
              >
                Next &rarr;
              </Button>
            </div>
          </div>
        </div>
      </Show>
      <Show condition={isPageBuilderActive}>
        <PageBuilderPreview />
      </Show>
    </>
  );
};
