import { Box, Select, TextField } from "@material-ui/core";
import { MenuItem } from "material-ui";
import { MuiThemeProvider } from "material-ui/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Course from "../../../../../utils/Course";
import {
  removeLiveSession,
  removeMulitipleLiveSession,
  saveSchedule,
  saveScheduleSingle,
} from "../../../../../../store/actions/liveCourse";
import { useHistory } from "react-router-dom";
import ScheduleItemCard from "./ScheduleItemCard";

const ScheduleItem = ({ props, item, timeZone, singleSessionList }) => {
  const id = window.location.pathname.split("/")[2];
  const history = useHistory();
  const session = window.location.pathname.split("/")[4];
  const cId = window.location.pathname.split("/")[3];
  const [loading, setLoading] = useState(false);
  const singleList = singleSessionList?.find((item) => item.id === id);
  const [image1, setImage1] = useState("");
  const [data, setData] = useState({
    image: "",
    name: "",
    Startdate: "",
    start_time: "",
    endDate: "",
    endTime: "",
  });
  const [singleData, setSingleData] = useState({
    image: "",
    timezone: "",
    name: "",
    Startdate: "",
    start_time: "",
    endDate: "",
    start_url: "",
    join_url: "",
    endTime: "",
  });
  const dispatch = useDispatch();
  const handleChange1 = (e) => {
    setLoading(true);
    const rootUrl =
      process.env.NODE_ENV === "production"
        ? "https://uploader.viosa.in/api"
        : "https://uploader.viosa.in/api";
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    fetch(`${rootUrl}/v2/upload/other`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((resp) => {
        setImage1(resp.link);
        setLoading(false);

        setData({ ...data, image: resp.link });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const removeLiveSessions = (id) => {
    debugger;
    window.confirm("Are you sure you want to delete this session?");
    dispatch(removeMulitipleLiveSession(id));
    dispatch(removeLiveSession(id));
  };

  const inputHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setSingleData({
      ...singleData,
      [e.target.name]: e.target.value,
    });
  };

  const saveData = (id) => {
    dispatch(saveSchedule({ id: id, data: data }));
    toast.success("✔ Session Detail Added!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const saveSingle = (id) => {
    Course.zoomMeeting((resp, err) => {
      if (err)
        toast.error(err.message, {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      const data = {
        id: id,
        courseId: cId,
        data: singleData,
        start_url: resp.response.start_url,
        join_url: resp.response.join_url,
      };
      if (resp.response.start_url && resp.response.join_url) {
        Course.liveSessionApi((resp, err) => {
          if (err)
            toast.error(err.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          dispatch(saveScheduleSingle(data));
        }, data);
      }
      toast.success("✔ Session Detail Added!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        history.push(`/course/${cId}`);
      }, 1000);
    }, singleData);
  };

  return (
    <>
      <ToastContainer />
      <MuiThemeProvider>
        {loading && <div id="cover-spin"></div>}
        {!timeZone ? (
          item.map((i) => (
            <ScheduleItemCard i={i} removeLiveSessions={removeLiveSessions} />
          ))
        ) : (
          <div className="schedule-card card-2 mb-4">
            <Box display="flex" flexDirection="column" m={10}>
              <div className="d-flex">
                <div className="to-column">
                  {image1 ? (
                    <img
                      id="uloaded-image"
                      style={{ width: "275px", height: "225px" }}
                      src={image1 || singleList?.image}
                      alt=""
                    />
                  ) : (
                    <img
                      id="uloaded-image"
                      style={{ width: "275px", height: "225px" }}
                      src="https://play-lh.googleusercontent.com/-Kd31zu1MYti5qi5HjTwYCMRhb-f4JyLd92lNhaGYyiOu_Of1jd6w462330C9krmTuo"
                      alt=""
                    />
                  )}

                  <div>
                    <button className="mt-3 mb-4 select-button">
                      <input onChange={handleChange1} type="file" />
                    </button>
                  </div>
                </div>

                <div style={{ width: "100%", marginLeft: "30px" }}>
                  <div className="d-flex justify-content-end"></div>
                  {timeZone && (
                    <Select
                      id="time"
                      label="Timezone"
                      type="time"
                      defaultValue={
                        singleList?.timeZone ||
                        "(UTC + 05:30) Chennai, Kolkata, Mumbai, New Delhi - IST"
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem
                        selected
                        value="(UTC + 05:30) Chennai, Kolkata, Mumbai, New Delhi - IST"
                      >
                        (UTC + 05:30) Chennai, Kolkata, Mumbai, New Delhi - IST
                      </MenuItem>
                    </Select>
                  )}
                  <TextField
                    id="time"
                    label="Session Name"
                    type="text"
                    name="name"
                    value={data.name || singleList?.name || ""}
                    onChange={inputHandler}
                    style={{ marginRight: 20 }}
                    // className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <div className="d-flex justify-content-between mt-4">
                    <TextField
                      id="time"
                      label="Start Date"
                      type="date"
                      value={data.Startdate || singleList?.Startdate || ""}
                      name="Startdate"
                      defaultValue="00:00:00"
                      onChange={inputHandler}
                      style={{ marginRight: 20 }}
                      // className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="time"
                      label="Start Time"
                      type="time"
                      onChange={inputHandler}
                      name="start_time"
                      value={data.start_time || singleList?.start_time || ""}
                      defaultValue="00:00:00"
                      // className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-between mt-4">
                    <TextField
                      id="time"
                      label="End Date"
                      type="date"
                      defaultValue="00:00:00"
                      style={{ marginRight: 20 }}
                      name="endDate"
                      value={data.endDate || singleList?.endDate || ""}
                      onChange={inputHandler}
                      // className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="time"
                      label="End Time"
                      name="endTime"
                      type="time"
                      value={data.endTime || singleList?.endTime || ""}
                      onChange={inputHandler}
                      defaultValue="00:00:00"
                      // className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Box>
            <div className="d-flex justify-content-end flex-end">
              <button
                className="save-button"
                onClick={() => saveSingle(singleList?.id)}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </MuiThemeProvider>
    </>
  );
};

export default ScheduleItem;
