import { Box } from "@material-ui/core";
import { Button } from "bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { clearSection } from "../../../../store/actions/section";
import Course from "../../../utils/Course";
import { UploadForm } from "../Courses/UploadForm";
import defualtImg from "../../../../images/no-image.png";
import { getCoursesList } from "../../../../services/PostsService";
import Multiselect from "multiselect-react-dropdown";

import {
  addCourseData,
  CoursePublished,
} from "../../../../store/actions/course";

export const PublishBundle = () => {
  const { section } = useSelector((state) => state.section);
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const [cover, setCover] = useState("");

  const cId = window.location.pathname.split("/")[2];

  const [thumbnailLink, setThumbNailLink] = useState("");
  const { categoryItems } = useSelector((state) => state.categoryReducer);
  const { singleSessionList } = useSelector((state) => state.livesession);

  const singleSessionListFiltered = singleSessionList.filter(
    (item) => item.courseId === cId
  );
  const [options, setOptions] = useState([]);
  let courseName = localStorage.getItem("courseName");
  const [name, setName] = useState(courseName);
  const [price, setPrice] = useState("");
  const [isNewCourse, setIsNewCourse] = useState(false);
  const [downloadCertificate, setDownloadCertificate] = useState(null);
  const [description, setDescription] = useState("");
  const courseHandler = (e) => {
    setName(e.target.value);
  };
  useEffect(() => {
    Course.getCourseSection((resp, err) => {
      setDescription(resp?.courses.description);
      setName(resp?.courses.name);
      setPrice(resp?.courses.price);
      setThumbNailLink(resp?.courses.thumbnail);
    }, cId);
  }, []);

  const modiFiedSections = section[cId]?.map(
    ({ _id: sID, chapter, ...rest }) => ({
      sID,
      ...rest,
      chapter: chapter.map(({ _id: cID, ...rest }) => ({
        cID,
        ...rest,
      })),
    })
  );

  console.log(modiFiedSections);
  const submitCourse = () => {
    if (!price || !name.length > 0) {
      console.log("error");
      toast.error("Please Enter All Field i.e Name, Description, Price", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setIsLoading(true);

    const data = {
      name: name,
      price: price,
      thumbnail: thumbnailLink,
      isPublished: true,
      description: description,
      section: modiFiedSections,
      category: "",
      liveSession: singleSessionListFiltered,
      courseId: cId,
    };
    Course.courseUpdate(
      (res, err) => {
        if (err) {
          setIsLoading(false);
          return;
        }
        dispatch(CoursePublished(true));
        setIsLoading(false);
        toast.success("✔ Course Updated!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      data,
      cId
    );
  };

  useEffect(() => {
    if (cover) {
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      const formData = new FormData();
      formData.append("file", cover);
      setIsLoading(true);

      fetch(`${rootUrl}/v2/upload/other`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setThumbNailLink(data.link);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [cover?.lastModified]);

  return (
    <div>
      {loading && <div id="cover-spin"></div>}
      <div className="d-flex justify-content-between">
        <div
          className="cropme col-sm-3"
          data-width="400"
          data-height="300"
          data-imagetype="coursethumb"
          data-identity="977adfd7-65af-40d6-8b03-0ef00068ae9d"
          data-accept=".jpg,.jpeg,.png"
        >
          <span className="close"></span>
          <img
            style={{
              width: "300px",
              height: "300px",
              objectFit: "contain",
            }}
            src={
              thumbnailLink ||
              "https://images.unsplash.com/photo-1645181196184-3ae5bf3fc91d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80"
            }
            className="mb-3"
            onError={(e) => {
              e.target.src = defualtImg;
              e.onerror = null;
            }}
          />
          <UploadForm
            className="mt-3"
            isImage={true}
            isPreview={false}
            cover={cover}
            setCover={setCover}
          />
        </div>

        <div
          className="col-sm-7 col-md-9 align-self-center"
          style={{ marginLeft: 200 }}
        >
          <h3 className="card-title mt-3">Bundle Name</h3>
          <div className="form-group bmd-form-group is-filled">
            <input
              type="text"
              className="form-control w-100 s_coursecurriculumsetting coursename"
              maxlength="100"
              placeholder="Course name"
              value={courseName || name || ""}
              onChange={(e) => courseHandler(e)}
            />
          </div>
        </div>
      </div>
      <div className="card-body px-0">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon">
                  <i class="fa-solid fa-pen-to-square"></i>
                </div>
              </div>
              <div>
                <h4 className="card-title">
                  Select a pricing plan for your bundle
                </h4>
              </div>
              <div className="card-body border-1 d-flex">
                <div className="d-flex flex-column col-11">
                  <h4 className="cart-title">Free</h4>
                  <p>
                    Offer free content to your users . Optionally set an expiry
                    date to the enrollment duration for limited-time-access{" "}
                  </p>
                </div>
                <div className="col-3">
                  <button className="btn btn-success">ADD</button>
                </div>
              </div>
              <div className="card-body border-1 d-flex my-1">
                <div className="d-flex flex-column col-11">
                  <h4 className="cart-title">One Time Payment</h4>
                  <p>Charge your users a one time free to access your bundle</p>
                </div>
                <div className="col-3">
                  <button className="btn btn-success">ADD</button>
                </div>
              </div>
              <div className="card-body border-1 d-flex my-1">
                <div className="d-flex flex-column col-11">
                  <h4 className="cart-title">Subcription/MemberShip</h4>
                  <p>
                    Offer free content to your users . Optionally set an expiry
                    date to the enrollment duration for limited-time-access{" "}
                  </p>
                </div>
                <div className="col-3">
                  <button className="btn btn-success">ADD</button>
                </div>
              </div>
              <div className="card-body border-1 d-flex my-1">
                <div className="d-flex flex-column col-11">
                  <h4 className="cart-title">Montly Payment Plan/EMI</h4>
                  <p>
                    Offer free content to your users . Optionally set an expiry
                    date to the enrollment duration for limited-time-access{" "}
                  </p>
                </div>
                <div className="col-3">
                  <button className="btn btn-success">ADD</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body px-0">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon">
                  <i class="fa-solid fa-pen-to-square"></i>
                </div>
              </div>
              <div>
                <h4 className="card-title">Bundle Price</h4>
              </div>
              <table class="table w-full">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {categoryItems.map((category) => (
                    <tr key={category.id}>
                      <td>{category.name}</td>
                      <td>{category.date}</td>
                      <td>{category.description}</td>
                      <td>
                        <div
                          className="cursor-pointer"
                          onClick={() => deleteCategory(category.id)}
                        >
                          Delete
                        </div>
                      </td>
                    </tr>
                  ))} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <button className="btn btn-primary" onClick={submitCourse}>
          Publish Bundle
        </button>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};
