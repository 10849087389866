import localStorage from "redux-persist/es/storage";
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history, setloader) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        saveTokenInLocalStorage({
          // kind: "identitytoolkit#VerifyPasswordResponse",
          // localId: "qmt6dRyipIad8UCc0QpMV2MENSy1",
          // email: "demo@example.com",
          // displayName: "",
          idToken: response?.data?.token,
          // registered: true,
          refreshToken: response?.data?.token,
          // expiresIn: 9823833600,
          // expireDate: null,
        });
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("userId", response?.data?.data._id);
        localStorage.setItem("role", response?.data?.role);
        // Cookies.set("token", response?.data?.token)
        // localStorage.setItem("token", response?.data?.token);
        setloader(true);
        history.push("/dashboard");
        // runLogoutTimer(dispatch, 368827270000 * 1000, history);
        dispatch(loginConfirmedAction(response.data));
      })
      .catch((error) => {
        //console.log(error);
        console.log(error)
        setloader(false);
        alert("Invalid Credentials");
        // const errorMessage = formatError(error.response.data);
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
