import React, { useState, useEffect } from "react";
import { exportToExcel } from "./exportToExcel"; // Import the function for exporting to Excel
import CommonTable from "../../../Common/Table/CommonTable";
import moment from "moment";
export const Form = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [collegeFilter, setCollegeFilter] = useState("");
  const [batchFilter, setBatchFilter] = useState("");

  useEffect(() => {
    // Simulate fetching data from a database
    const fetchData = async () => {
      try {
        // Perform API call to fetch data from the database
        const response = await fetch("https://api.viosa.in/api/getformdata");
        const jsonData = await response.json();
        // Assuming your API response is an array of objects
        setData(jsonData.data);
        setFilteredData(jsonData.data); // Initialize filteredData with all data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Run this effect only once, on component mount

  const columns = [
    {
      name: 'S.No',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,

    },
    {
      name: 'College',
      selector: row => row.college,
      sortable: true,
    },
    {
      name: 'Batch',
      selector: row => row.batch,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    }
    ,
    {
      name: 'Phone no.',
      selector: row => row.phone,
      sortable: true,
    }
    ,
    {
      name: 'Class Name',
      selector: row => row.className,
      sortable: true,
    }
    ,
    {
      name: 'Roll No.',
      selector: row => row.rollNo,
      sortable: true,
    }
    ,
    {
      name: 'Date',
      selector: row => moment(row.timestamp).format("DD-MM-YYYY"),
      sortable: true,

    }

  ];

  const handleFilter = () => {
    let filtered = [...data];
    if (collegeFilter) {
      filtered = filtered.filter((item) =>
        item.college.toLowerCase().includes(collegeFilter.toLowerCase())
      );
    }
    if (batchFilter) {
      filtered = filtered.filter((item) =>
        item.batch.toLowerCase().includes(batchFilter.toLowerCase())
      );
    }
    setFilteredData(filtered);
  };

  const csvData = data.map((row, index) => ({
    "S No.": index + 1,
    "Name": row.name,
    "College": row.college,
    "Batch": row.batch,
    "Email": row.email,
    "Phone No.": row.phone,
    "Class Name": row.className,
    "Roll No.": row.rollNo,
    "Date": moment(row.timestamp).format("DD-MM-YYYY")
  }));


  const [searchQuery, setsearchQuery] = useState('')

  useEffect(() => {
    handleSearchChange()
  }, [searchQuery, data]);

  const handleSearchChange = async (e) => {
    if (searchQuery === '') {
      setFilteredData(data);
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filteredResults = data.filter(item =>
        Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredData(filteredResults);
    }
  }
  return (
    <div className="card-3">
      <div>

        <CommonTable columns={columns} data={filteredData} csvName="Form-Data" csvData={csvData} setsearchQuery={setsearchQuery} />
      </div>
    </div>
  );
};
