import produce from "immer";
const initialState = {
  section: {},
  chapter: [],
  isExpandedValue: false,
  otherFile: "",
  sectionName: "",
  assesment: [],
  embeddedLink: "",
  nextBtn: "",
};

export const SectionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "ADD_SECTION": {
        const olderValue = draft?.section[action.payload.data.courseId] || [];
        draft.section[action.payload.data.courseId] = [
          ...olderValue,
          action.payload.data,
        ];
        break;
      }

      case "ALL_SECTION_LIST": {
        draft.section[action.payload.data.courseId] = [
          ...action.payload.data.sections,
        ];
        break;
      }
      case "DELETE_SECTION": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.filter(
          (item) => item.sectionID !== action.payload.data.id
        );
        break;
        // return {
        //   ...state,
        //   section: [...state.section].filter(
        //     (item) => item.sectionID !== action.payload.data
        //   ),
      }
      case "ADD_CHAPTER": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.section) {
            return {
              ...sect,
              chapter: sect.chapter.concat([action.payload.data]),
              isExpandedValue: true,
            };
          }
          return sect;
        });
        break;
      }
      case "ADD_ALL_CHAPTERS": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.section) {
            sect.chapter = action.payload.data.data;
          }
          return sect;
        });
        break;
      }
      case "REMOVE_CHAPTER": {
        // }; //   section: newChapter, //   ...state, // return { // }); //   }; //     ), //       (item) => item.chapterID !== action.payload.data + 1 //     chapter: sect.chapter.filter( //     ...sect, //   return { // const newChapter = state.section.map((sect) => {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (Number(sect.sectionID) === action.payload.data.section) {
            sect.chapter = sect.chapter.filter(
              (item) => item.chapterID !== action.payload.data.id
            );
          }
          return sect;
        });
        break;
      }
      case "ADD_CONTENT": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.map((ch) => {
              if (ch.chapterID === action.payload.data.chapterID) {
                ch.chapterName = action.payload.data.chapterName;
              }
            });
          }
          return sect;
        });
        break;
      }

      case "ADD_VIDEO": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (Number(sect.sectionID) == action.payload.data.sectionID) {
            sect.chapter.map((ch) => {
              if (ch.chapterID === action.payload.data.chapterID) {
                ch.videoLink = action.payload.data.videoLink;
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_HTML": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (Number(sect.sectionID) === action.payload.data.sectionID) {
            sect.chapter.map((ch) => {
              if (ch.chapterID === action.payload.data.chapterID) {
                ch.html = action.payload.data.html;
              }
            });
          }
          return sect;
        });
        break;
      }

      case "ADD_PDF": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.map((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.pdfLink = action.payload.data.pdfLink;
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_URL_LINK": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.map((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.url = action.payload.data.url;
              }
            });
          }
          return sect;
        });
        break;
      }

      case "OTHER_FILE": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.map((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.otherFile = action.payload.data.otherLink;
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_ASSESSMENT": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment = [
                  ...ch.assessment,
                  action.payload.data.questions,
                ];
              }
            });
          }
          return sect;
        });
        break;
      }

      case "REMOVE_ASSESSMENT_LIST": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment = ch.assessment.filter(
                  (a) => a.qid !== action.payload.data.qid
                );
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_OPTIONS": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        debugger;
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              debugger;
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    console.log(a, action.payload.data);
                    a.options = [...a.options, action.payload.data.option];
                  }
                });
                // console.log(ch.assesment);
                // ch.assesment = [...ch.assesment, action.payload.data.questions];
              }
            });
          }
          return sect;
        });
        break;
      }
      case "REMOVE_ASSESSMENT_OPTION": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        debugger;
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              debugger;
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    console.log(a, action.payload.data);
                    a.options = a.options.filter(
                      (item) => item.optId !== action.payload.data.optId
                    );
                  }
                });
                // console.log(ch.assesment);
                // ch.assesment = [...ch.assesment, action.payload.data.questions];
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_QUESTIONS_NAME": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.question = action.payload.data.question;
                  }
                });
              }
            });
          }
          return sect;
        });
        break;
      }
      case "PASSING_MARKS_VALUE": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.passingMarks = action.payload.data.passingMarks;
                  }
                });
              }
            });
          }
          return sect;
        });
        break;
      }
      case "NUMBER_OF_ATTEMP_VALUE": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.numberOfAttemp = action.payload.data.numberOfAttemp;
                  }
                });
              }
            });
          }
          return sect;
        });
        break;
      }
      case "DURATION_VALUE": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.duration = action.payload.data.duration;
                  }
                });
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_HINT_VALUE": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.hint = action.payload.data.hint;
                  }
                });
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_MARKS_VALUE": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.marks = action.payload.data.marks;
                  }
                });
              }
            });
          }
          return sect;
        });
        break;
      }
      case "CORRECT_ANSWER": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.correctAnswer = action.payload.data.correctAnswer;
                  }
                });
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_OPTION_VALUE": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.options.forEach((o) => {
                      if (o.optId === action.payload.data.optId) {
                        o.name = action.payload.data.name;
                      }
                    });
                  }
                });
                // console.log(ch.assesment);
                // ch.assesment = [...ch.assesment, action.payload.data.questions];
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_VIDEO_OPTION": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID === action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.options.forEach((o) => {
                      if (o.optId == action.payload.data.optId) {
                        o.value = action.payload.data.value;
                      }
                    });
                  }
                });
              }
            });
          }
          return sect;
        });
        break;
      }
      case "ADD_IMAGE_OPTION": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.forEach((ch) => {
              if (ch.chapterID == action.payload.data.chapterID) {
                ch.assessment.forEach((a) => {
                  if (a.qid == action.payload.data.qid) {
                    a.options.forEach((o) => {
                      if (o.optId == action.payload.data.optId) {
                        o.image = action.payload.data.image;
                      }
                    });
                  }
                });
              }
            });
          }
          return sect;
        });
        break;
      }

      case "REMOVE_ASSESSMENT":
        const newAssessment = state.section.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.map((element) => {
              if (element.chapterID == action.payload.data.chapter) {
                element.assesment = element.assesment.filter(
                  (item) => item.id !== action.payload.data.id
                );
              }
            });
          }
          return sect;
        });

        return {
          ...state,
          section: newAssessment,
        };
      case "DELETE_SECTION":
        return {
          ...state,
          chapter: [...state.chapter].filter(
            (item) => item.section !== action.payload.data
          ),
        };
      case "EXPAND":
        return {
          ...state,
          isExpandedValue: action.payload.data,
        };
      case "ADD_NEXT_BTN":
        return {
          ...state,
          nextBtn: action.payload.data,
        };
      case "ADD_EMBEDDED_LINK":
        return {
          ...state,
          embeddedLink: action.payload.data,
        };
      case "ADD_EMBEDDED_LINK_IN_CHAPTER":
        return {
          ...state,
          embeddedLink: action.payload.data,
        };

      case "ADD_SECTION_NAME": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionId) {
            return {
              ...sect,
              sectionName: action.payload.data.sectionName,
            };
          }
          return sect;
        });
        break;
      }
      case "ADD_ACTIVE_DATE": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            return {
              ...sect,
              activeDate: action.payload.data.activeDate,
            };
          }
          return sect;
        });
        break;
      }

      case "ADD_DRIP_BY": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            return {
              ...sect,
              dripBy: action.payload.data.drip,
            };
          }
          return sect;
        });
        break;
      }

      case "ADD_DRIP_BY_DATE": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.availableAfterDays =
              action.payload.data.availableAfterDays || 0;
            sect.availableAfterWeeks =
              action.payload.data.availableAfterWeeks || 0;
          }
          return sect;
        });
        break;
      }

      case "ADD_ACTIVE_DATE_CHAPTER": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.map((element) => {
              if (element.chapterID === action.payload.data.chapterID) {
                element.activeDate = action.payload.data.activeDate;
              }
            });
          }
          return sect;
        });
        break;
      }

      case "ADD_DRIP_BY_CHAPTER": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (sect.sectionID == action.payload.data.sectionID) {
            sect.chapter.map((element) => {
              if (element.chapterID === action.payload.data.chapterID) {
                element.dripBy = action.payload.data.drip;
              }
            });
          }
          return sect;
        });
        break;
      }

      case "ADD_DRIP_BY_DATE_CHAPTER": {
        const olderValue = draft.section[action.payload.data.courseId] ?? [];
        draft.section[action.payload.data.courseId] = olderValue.map((sect) => {
          if (
            sect.sectionID == action.payload.data.sectionID ||
            Number(action.payload.data.sectionID)
          ) {
            sect.chapter.map((element) => {
              if (element.chapterID === action.payload.data.chapterID) {
                element.availableAfterDays =
                  action.payload.data.availableAfterDays || 0;
                element.availableAfterWeeks =
                  action.payload.data.availableAfterWeeks || 0;
              }
            });
          }
          return sect;
        });
        break;
      }

      case "CLEAR_SECTION":
        return {
          ...state,
          section: action.payload.data,
        };
      default:
        return state;
    }
  });
