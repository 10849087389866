import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import profile from "../../images/viosa.png";
import { ThemeContext } from "../../context/ThemeContext";
import { CiUser } from "react-icons/ci";
import { AiOutlineUserAdd } from "react-icons/ai";
import { SiGoogledocs } from "react-icons/si";
import { GoPencil } from "react-icons/go";
import { MdOutlineSchedule } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";


import {
    getCareer,
    getCollab,
    getCoursesList,
    getOrders,
    getSupport,
    getUsers,
} from "../../services/PostsService";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getAdminInterviewProDashboardData, getAdminResumaticDashboardData } from "../../services/apis";
import Loader from "../../Common/Loader/Loader";

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [10, 20.50],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
    ],
};

const ResumaticDashboard = () => {
    const { changeBackground } = useContext(ThemeContext);
    const [dashboardData, setdashboardData] = useState([])
    const [loading, setloading] = useState(false)

    const fetchDashboardData = async () => {
        try {
            setloading(true)
            const res = await getAdminResumaticDashboardData()
            console.log(res)
            setdashboardData(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        fetchDashboardData()
    }, [])

    return (
        <>
            <section>

                {
                    loading ?
                        <Loader />
                        :
                        <>

                            <div className=" col-12 d-flex justify-content-between gap-5 flex-column">
                                <div className="d-flex justify-content-between gap-5">

                                    <div className="w-100 bg-white rounded">
                                        <div className="card-body">
                                            <span className="d-flex gap-3 align-items-center">
                                                <CiUser fontSize={24} />
                                                <h5 className="mb-0">No of Users</h5>
                                            </span>
                                            <h3 className="ps-5 mt-3">{dashboardData?.totalUsers}</h3>
                                        </div>
                                    </div>
                                    <div className="w-100 bg-white rounded">
                                        <div className="card-body">
                                            <span className="d-flex gap-3 align-items-center">
                                                <AiOutlineUserAdd fontSize={24} />
                                                <h5 className="mb-0">Ai Credits</h5>
                                            </span>
                                            <h3 className="ps-5 mt-3">{dashboardData?.aicredits}</h3>
                                        </div>

                                    </div>

                                    {/* <div className="w-100 bg-white rounded">
                                        <div className="card-body">
                                            <span className="d-flex gap-3 align-items-center">
                                                <SiGoogledocs fontSize={24} />
                                                <h5 className="mb-0">Resume Received</h5>
                                            </span>
                                            <h3 className="ps-5 mt-3">{dashboardData?.resumeRecieved}</h3>
                                        </div>

                                    </div> */}
                                </div>
                                <div className="d-flex justify-content-between gap-5">
                                    {/* 
                                    <div className="w-100 bg-white rounded">
                                        <div className="card-body">
                                            <span className="d-flex gap-3 align-items-center">
                                                <GoPencil fontSize={24} />
                                                <h5 className="mb-0">Jobs Posted</h5>
                                            </span>
                                            <h3 className="ps-5 mt-3">{dashboardData?.jobPosted}</h3>
                                        </div>
                                    </div>
                                    <div className="w-100 bg-white rounded">
                                        <div className="card-body">
                                            <span className="d-flex gap-3 align-items-center">
                                                <MdOutlineSchedule fontSize={24} />
                                                <h5 className="mb-0">Interview Scheduled</h5>
                                            </span>
                                            <h3 className="ps-5 mt-3">{dashboardData?.interviewScheduled}</h3>
                                        </div>
                                    </div>

                                    <div className="w-100 bg-white rounded">
                                        <div className="card-body">
                                            <span className="d-flex gap-3 align-items-center">
                                                <FiUserCheck fontSize={24} />
                                                <h5 className="mb-0">Total Selection</h5>
                                            </span>
                                            <h3 className="ps-5 mt-3">{dashboardData?.totalSelection}</h3>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className="bg-white mt-4 rounded p-4">
                <h4>Statistics of Interview</h4>
                <Line options={options} data={data} />;
                <div>
                </div>
              </div>
              <div className="bg-white mt-4 rounded p-4">
                <h4>Statistics of Candidate</h4>
                <Line options={options} data={data} />;
              </div> */}
                        </>
                }

            </section>
        </>
    );
};
export default ResumaticDashboard;



