import { Box, Select, Slider, TextField } from "@material-ui/core";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { MenuItem } from "material-ui";
import React, { useEffect, useState } from "react";
import { timeZoneData } from "./data/_data";
import { useDispatch } from "react-redux";
import { multipleSession } from "../../../../../store/actions/liveCourse";

export const Details = ({ selected }) => {
  const dispatch = useDispatch();
  const cId = window.location.pathname.split("/")[3];

  const [value1, setValue1] = useState(0);
  const [arrayData, setArrayData] = useState([]);
  const changeValue = (event, value) => {
    setValue1(value);
  };
  const [data, setData] = useState({
    image: "",
    name: "",
    Startdate: "",
    start_time: "",
    endDate: "",
    endTime: "",
  });

  const inputHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (value1 > 0) {
      const sessionArray = [...Array(value1).keys()];
      const generateArray = sessionArray.map((item) => ({
        name: "",
        id: item,
        start_time: "",
        Startdate: "",
        sessionName: "multiple-session",
        endTime: "",
        endDate: "",
        courseId: cId,
      }));
      setArrayData(generateArray);
    }
  }, [value1]);

  useEffect(() => {
    const modifyArray = arrayData.map((item) => {
      (item.start_time = data.start_time),
        (item.endDate = data.endDate),
        (item.endTime = data.endTime),
        (item.Startdate = data.Startdate);
      return item;
    });
    let arrayUniqueByKey = modifyArray.filter(
      (item) => item.Startdate === data.Startdate
    );
    console.log(arrayUniqueByKey);
    if (selected == "multiple-session" && value1 > 0) {
      dispatch(multipleSession(arrayUniqueByKey));
    }
  }, [arrayData, data, selected]);

  const [date, month] = new Date().toLocaleString().split("/");
  console.log(Object.keys(timeZoneData));

  const getText = (valu) => `${value1}`;
  return (
    <div className="schedule-card card-2">
      <MuiThemeProvider>
        <Box display="flex" flexDirection="column" m={10}>
          <Select
            id="time"
            label="Timezone"
            type="time"
            defaultValue="(UTC + 05:30) Chennai, Kolkata, Mumbai, New Delhi - India Standard TIme"
            InputLabelProps={{
              shrink: true,
            }}
          >
            {Object.keys(timeZoneData)?.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
          <div className="d-flex align-items-center mt-4">
            <Slider
              style={{ width: "90%" }}
              value={value1}
              //   marks
              onChange={changeValue}
              valueLabelDisplay="auto"
              getAriaValueText={getText}
            />
            <input
              className="form-control"
              value={value1}
              style={{
                width: "10%",
                border: "2px solid #333",
                outline: "none",
              }}
              onChange={changeValue}
              type="text"
            />
          </div>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            value={data.Startdate || ""}
            name="Startdate"
            defaultValue="00:00:00"
            onChange={inputHandler}
            // className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className="d-flex justify-content-between mt-3">
            <TextField
              id="time"
              label="Start Time"
              type="time"
              onChange={inputHandler}
              name="start_time"
              value={data.start_time || ""}
              defaultValue="00:00:00"
              style={{ marginRight: 20 }}
              // className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="time"
              label="End Time"
              type="time"
              name="endDate"
              value={data.endDate || ""}
              onChange={inputHandler}
              defaultValue="00:00:00"
              // className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </Box>
      </MuiThemeProvider>
    </div>
  );
};
