import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./graph.css";

export const Graph = ({ data, title, setSelect }) => {
  const [typeSelected, setTypeSelected] = useState("30");
  const state = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Users",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: data,
      },
    ],
  };
  return (
    <div className="graphContainer">
      <div className="heading">
        <div className="left"> {title} </div>
        <div className="right">
          <div
            className={typeSelected === "30" ? "selected item" : "item"}
            onClick={() => {
              setTypeSelected("30");
              setSelect && setSelect(30);
            }}
          >
            Last 30 Days
          </div>
          <div
            className={typeSelected === "12" ? "selected item" : "item"}
            onClick={() => {
              setTypeSelected("12");
              setSelect && setSelect(12);
            }}
          >
            Last 12 Months
          </div>
        </div>
      </div>
      <div className="graph">
        <ResponsiveContainer height={300} id="res">
          <AreaChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#8884d8"
              fill="#FFA303"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
