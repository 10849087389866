const initialState = {
  courses: [],
  isPublished: false,
  isPageBuilderActive: false,
  isPageBuilderData: {},
};

export const CourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_COURSE":
      return {
        ...state,
        courses: [...state.courses, action.payload.data],
      };
    case "ALL_COURSES":
      return {
        ...state,
        courses: action.payload.data,
      };

    case "COURSE_PUBLISHED":
      return {
        ...state,
        isPublished: action.payload.data,
      };
    case "COURSE_PAGE_BUILDER":
      return {
        ...state,
        isPageBuilderActive: action.payload.data,
      };
    case "COURSE_PAGE_BUILDER_DATA":
      return {
        ...state,
        isPageBuilderData: action.payload.data,
      };
    default:
      return state;
  }
};
