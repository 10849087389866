import React from "react";
import { Link } from "react-router-dom";
import "./landingpage.css";
const LandingPage = () => {
  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="course-card">
              <div class="course-card-img">
                <img
                  src="https://preview.ibb.co/bMi5Y6/banner_img.png"
                  class="main"
                  alt=""
                />
                <img
                  src="https://preview.ibb.co/bMi5Y6/banner_img.png"
                  class="layered"
                  alt=""
                />
              </div>
              <div class="course-card-content">
                <h4>Landing Page</h4>
                <Link to="/demo1">DEMO</Link>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="course-card">
              <div class="course-card-img">
                <img
                  src="https://preview.ibb.co/bMi5Y6/banner_img.png"
                  class="main"
                  alt=""
                />
                <img
                  src="https://preview.ibb.co/bMi5Y6/banner_img.png"
                  class="layered"
                  alt=""
                />
              </div>
              <div class="course-card-content">
                <h4>Landing Page</h4>
                <Link to="/demo2">Career Assessment</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
