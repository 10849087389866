import React, { useEffect } from 'react'
import { useState } from 'react'
import { CiUser } from 'react-icons/ci'
import { getAllDashboardData } from '../services/apis'
import Loader from '../Common/Loader/Loader'

function CollegeDashboard() {
    const [loading, setloading] = useState(false)
    const [collegeDashboardData, setcollegeDashboardData] = useState(null)
    useEffect(() => {
        fetchDashboardData()
    }, [])

    const fetchDashboardData = async () => {
        try {
            setloading(true)
            const res = await getAllDashboardData()
            setloading(false)
            console.log(res)
            setcollegeDashboardData(res.data.data)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    return (
        <>
            {
                loading ? <Loader /> :
                    <div className=" col-12 d-flex justify-content-between gap-5 flex-column">
                        <div className="d-flex justify-content-between gap-5">
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">
                                        <CiUser fontSize={24} />
                                        <h5 className="mb-0">No of Total Users</h5>
                                    </span>
                                    <h3 className="ps-5 mt-3">{collegeDashboardData?.noofstudent}</h3>
                                </div>
                            </div>
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">
                                        <CiUser fontSize={24} />
                                        <h5 className="mb-0">No of Interview Pro Users</h5>
                                    </span>
                                    <h3 className="ps-5 mt-3">{collegeDashboardData?.interviewprouser}</h3>
                                </div>
                            </div>
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">
                                        <CiUser fontSize={24} />
                                        <h5 className="mb-0">No of Resumatic Users</h5>
                                    </span>
                                    <h3 className="ps-5 mt-3">{collegeDashboardData?.resumaticuser}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between gap-5">
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">
                                        <CiUser fontSize={24} />
                                        <h5 className="mb-0">No of Courses</h5>
                                    </span>
                                    <h3 className="ps-5 mt-3">{collegeDashboardData?.courses}</h3>
                                </div>
                            </div>
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">
                                        <CiUser fontSize={24} />
                                        <h5 className="mb-0">No. Of Batches</h5>
                                    </span>
                                    <h3 className="ps-5 mt-3">{collegeDashboardData?.batches}</h3>
                                </div>
                            </div>
                            {/* <div className="w-100 bg-white rounded">
                        <div className="card-body">
                        <span className="d-flex gap-3 align-items-center">
                        <CiUser fontSize={24} />
                        <h5 className="mb-0">No of Resumatic Users</h5>
                        </span>
                        <h3 className="ps-5 mt-3">20</h3>
                        </div>
                    </div> */}
                        </div>
                    </div>
            }
        </>
    )
}

export default CollegeDashboard