import React, { useState, useEffect } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { FiArrowLeft } from 'react-icons/fi'
import { MdBackHand } from 'react-icons/md'
import InputMask from 'react-input-mask';

import { Link } from "react-router-dom";
import { EditBatches, PostBatches, getAllBatches, getAllColleges, getAllCourses, getAllInterview, getSingleBatchById } from '../../services/apis';
import MultiSelect from '../../Common/Components/MultiSelect';
import Loader from '../../Common/Loader/Loader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import MultiSelectCourses from '../../Common/Components/MultiSelectCourses';
import { useHistory } from "react-router-dom";

function AddBatches() {
    const [allColleges, setallColleges] = useState([])
    const [allInterviews, setallInterviews] = useState([])
    const [allCourses, setallCourses] = useState([])
    const [loadingColleges, setloadingColleges] = useState(false)
    const [edit, setedit] = useState(false)
    const param = useParams()
    const navigate = useHistory()
    const [batchesObject, setbatchesObject] = useState({
        name: "",
        studyclass: "",
        academicYear: "",
        course: [],
        collegeid: "",
        interview: [],
        course: []
    })
    console.log(batchesObject)
    const handleChange = (e) => {
        const { name, value } = e.target
        setbatchesObject({ ...batchesObject, [name]: value })
    }

    // multi select 

    const handleMultiSelectChange = (e) => {
        const { name, value } = e.target
        setbatchesObject({ ...batchesObject, interview: [...batchesObject.interview, value] })
    }
    const handleMultiSelectCourseChange = (e) => {
        const { name, value } = e.target
        console.log(value)
        setbatchesObject({ ...batchesObject, course: [...batchesObject.course, value] })
    }
    const removeMultiSelectCourseChange = (e, id) => {
        e.preventDefault()
        const newArr = batchesObject.course.filter((item) => item != id)
        setbatchesObject({ ...batchesObject, course: newArr })
    }
    const removeMultiSelectChange = (e, id) => {
        e.preventDefault()
        const newArr = batchesObject.interview.filter((item) => item != id)
        setbatchesObject({ ...batchesObject, interview: newArr })
    }
    const fetchColleges = async () => {
        try {
            const res = await getAllColleges()
            setallColleges(res.data.data)
        } catch (error) {
            setloadingColleges(false)
            console.log(error)
        }
    }
    const fetchCourses = async () => {
        try {
            const res = await getAllCourses()
            setallCourses(res.data.courses)
            console.log(res)
        } catch (error) {
            console.log(error)
        }
    }
    const fetchInterviews = async () => {
        try {
            setloadingColleges(true)
            const res = await getAllInterview()
            setallInterviews(res.data.interviews)
            setloadingColleges(false)
        } catch (error) {
            setloadingColleges(false)
            console.log(error)
        }
    }

    const fetchSingleBatch = async () => {
        try {
            const res = await getSingleBatchById(param.id)
            const singleBatch = res.data.data
            const newArr = singleBatch?.interview?.map((item) => item._id)
            singleBatch.interview = newArr
            setbatchesObject(singleBatch)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchColleges()
        fetchInterviews()
        fetchCourses()
        if (window.location.pathname.includes('edit')) {
            setedit(true)
            fetchSingleBatch()
        }
    }, [])
    const handleSubmitBatch = async (e) => {
        e.preventDefault()
        try {
            const res = await PostBatches(batchesObject)
            console.log(res)
            navigate.push('/batches')

        } catch (error) {
            console.log(error)
        }
    }

    const handleEditBatch = async (e) => {
        e.preventDefault()
        try {
            const res = await EditBatches(batchesObject)
            navigate.push('/batches')

        } catch (error) {
            console.log(error)
        }
    }
    const [isValid, setIsValid] = useState(true);


    const handleAcademicChange = (event) => {
        const year = event.target.value;
        setbatchesObject({ ...batchesObject, academicYear: year });
        setIsValid(validateAcademicYear(year));
    };

    const validateAcademicYear = (year) => {
        const regex = /^\d{4}-\d{2}$/;
        return regex.test(year);
    };

    return (
        <div className=''>
            {
                loadingColleges ?
                    <div className='h-100 d-flex justify-content-center'>
                        <Loader />
                    </div>
                    :
                    <>
                        <Link to={'/batches'} className='mb-3'>
                            <button className='common-btn px-3 p-2 rounded-2'><FiArrowLeft fontSize={20} /> </button>
                        </Link>
                        <div className='bg-white rounded p-5 mt-3'>
                            <Form>
                                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                                    <Form.Label> Select College</Form.Label>
                                    <select disabled={edit} className="form-select form-select-lg border border-dark" aria-label="Default select example" name="collegeid" onChange={handleChange}>
                                        <option selected disabled>
                                            {
                                                edit ? allColleges?.find((item) => item._id === batchesObject?.collegeid)?.collegename : "Select College"
                                            }
                                        </option>
                                        {
                                            allColleges?.map((item, index) => {
                                                return <option key={index} value={item._id}>{item?.collegename}</option>
                                            })
                                        }
                                    </select>
                                </Form.Group>
                                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                                    <Form.Label>Specialisation</Form.Label>
                                    <Form.Control value={batchesObject?.name} type="text" name="name" onChange={handleChange} className='border border-dark' placeholder="For eg. Mechanical" />
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Specialisation</Form.Label>
                                    <Form.Control value={batchesObject?.studyclass} type="text" name="studyclass" onChange={handleChange} className='border border-dark' placeholder="Branch" />
                                </Form.Group> */}
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Study Year</Form.Label>
                                    <select disabled={edit} className="form-select form-select-lg border border-dark" aria-label="Default select example" name="studyclass" onChange={handleChange}>
                                        <option selected disabled>
                                            {
                                                edit ? batchesObject?.studyclass : "Select Study Year"
                                            }
                                        </option>
                                        <option value={"1 Year"}>1 Year</option>
                                        <option value={"2 Year"}>2 Year</option>
                                        <option value={"3 Year"}>3 Year</option>
                                        <option value={"4 Year"}>4 Year</option>
                                        <option value={"5 Year"}>5 Year</option>
                                        <option value={"6 Year"}>6 Year</option>

                                    </select>

                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Academic Year</Form.Label>
                                    <div className='w-100'>
                                        <InputMask
                                            mask="9999-99"
                                            value={batchesObject?.academicYear}
                                            onChange={handleAcademicChange}
                                            placeholder="YYYY-YY"
                                            className='border border-dark w-100 rounded-2 px-3 p-2'
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" id="academicYear" />}
                                        </InputMask>
                                    </div>
                                </Form.Group>
                                <MultiSelectCourses handleMultiSelectChange={handleMultiSelectCourseChange} selectName="course" removeMultiSelectChange={removeMultiSelectCourseChange} placeholder="Add Courses" selectedData={batchesObject?.course} data={allCourses} />
                                <MultiSelect handleMultiSelectChange={handleMultiSelectChange} selectName="interview" removeMultiSelectChange={removeMultiSelectChange} placeholder="Add Interviews" selectedData={batchesObject?.interview} data={allInterviews} />

                                <div className='d-flex justify-content-end'>
                                    {
                                        !!param.id ?
                                            <button className='common-btn p-2 px-5 rounded-2' onClick={handleEditBatch}>Update </button>
                                            :
                                            <button className='common-btn p-2 px-5 rounded-2' onClick={handleSubmitBatch}>Submit </button>
                                    }
                                </div>
                            </Form>

                        </div>
                    </>
            }
        </div>
    )
}

export default AddBatches