import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Show } from "../../show";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getAllBundles } from "../../../../services/PostsService";

export const BundleCurriculam = (props) => {
  const [show, setShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [video, setVideo] = useState("");
  const [file, setFile] = useState("");

  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const [bundleList, setBundleList] = useState([]);
  const [sectionList, setSection] = useState([]);
  const [formData, setFormData] = useState({
    section: "",
    chapter: "",
    video: video,
    file: file,
  });

  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const content = [];

  useEffect(() => {
    const fetch = async () => {
      const response = await getAllBundles();
      setBundleList(response.data.newBundle);
    };
    fetch();
  }, []);

  const id = window.location.pathname.split("/")[2];
  const singleBundle = bundleList?.filter((item) => item._id === id);

  const [newList, setNewList] = useState(singleBundle[0]?.courseIds);

  useEffect(() => {
    setNewList(singleBundle[0]?.courseIds || []);
  }, [bundleList]);
  const onDragEnd = (result) => {
    const newItems = Array.from(newList);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setNewList(newItems);
    console.log(newItems);
  };
  console.log(newList);
  return (
    <>
      <Show condition={true}>
        <div></div>
      </Show>
      <Show condition={!show || show}>
        <div className="mt-5 pt-5">
          <div className="d-flex justify-content-center">
            <div className="card-3 col-12">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon">
                  <i class="fa-solid fa-pen-to-square"></i>
                </div>
              </div>
              <div>
                <h4 className="card-title">Courses</h4>
              </div>

              <div className="card-body border-1 d-flex">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        className="d-flex flex-column col-12"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {newList?.map((c, index) => (
                          <>
                            <Draggable
                              key={c.courseId}
                              draggableId={c.courseId || "hello"}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <>
                                  <div
                                    className="d-flex justify-content-between"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <p>{c.courseName}</p>
                                    <i
                                      class="cursor-pointer bi bi-grip-horizontal"
                                      style={{ fontSize: "20px" }}
                                    ></i>
                                  </div>
                                  <hr />
                                </>
                              )}
                            </Draggable>
                          </>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </Show>
    </>
  );
};
