import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";

import { logout } from "../../../store/actions/AuthActions";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { IoIosLogOut } from "react-icons/io";
function LogoutPage(props) {
  const dispatch = useDispatch();
  const history = useHistory()
  function onLogout() {
    window.location.reload();
    history.push('/login')
    localStorage.clear()
  }
  useEffect(() => {

  }, [])

  return (
    <>
      <button className="dropdown-item ai-icon mt-2" onClick={onLogout}>
        <IoIosLogOut fontSize={20} />
        <span className="ms-2">Logout </span>
      </button>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(LogoutPage));
