import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { singleSessionAction } from "../../../../../store/actions/liveCourse";
import { useDispatch } from "react-redux";

export const SessionModal = (props) => {
  const history = useHistory();
  const [active, setActive] = useState({
    isList1: "",
    isList2: "",
    isList3: "",
  });
  const [sessionId, setSessionId] = useState("");
  const dispatch = useDispatch();
  const [link, setLink] = useState("");

  const list1 = () => {
    setSessionId(Math.random(10000000).toString(29).substr(2, 9));
    setActive({
      isList1: "active-color",
      isList2: "",
      isList3: "",
    });
  };

  useEffect(() => {
    setLink(
      `/session/${sessionId}/${props.id}/${
        (active.isList1 && "single-session") ||
        (active.isList2 && "multiple-session") ||
        active.isList3
      }`
    );
  }, [sessionId]);

  const list2 = () => {
    setSessionId(Math.random(10000000).toString(29).substr(2, 9));
    setActive({
      isList1: "",
      isList2: "active-color",
      isList3: "",
    });
    setLink("/multiple-session");
  };
  const list3 = () => {
    setActive({
      isList1: "",
      isList2: "",
      isList3: "active-color",
    });
    setLink("/ongoing-session");
  };

  const navigatePage = () => {
    const singleSessionList = {
      image: "",
      name: "",
      Startdate: "",
      start_time: "",
      endDate: "",
      endTime: "",
      id: sessionId,
      timezone: "",
      start_url: "",
      join_url: "",
      courseId: props.id,
    };
    dispatch(singleSessionAction(singleSessionList));
    setTimeout(() => {
      history.push(link);
    }, 500);
  };

  return (
    <Modal {...props} className="fade bd-example-modal-lg" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add New Section</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <div class={`card ${active.isList1} border-1 mx-2`} onClick={list1}>
            <p>Single Session</p>
          </div>
          <div class={`card ${active.isList2} border-1 mx-2`} onClick={list2}>
            <p>Multiple Session</p>
          </div>
          <div class={`card ${active.isList3} border-1 mx-2`} onClick={list3}>
            <p>Ongoing Session</p>
          </div>
        </div>
        <div className="mt-2">Learn more about creating a live session</div>
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary" onClick={() => navigatePage()}>
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
