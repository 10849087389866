import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import { SectionReducer } from "./reducers/Section";
import { reducer as reduxFormReducer } from "redux-form";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { CourseReducer } from "./reducers/Course";
import { LiveReducer } from "./reducers/liveReducer";
import { CategoryReducer } from "./reducers/CategoryReducer";
import { TeamsReducer } from "./reducers/Teams";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  section: SectionReducer,
  form: reduxFormReducer,
  courses: CourseReducer,
  teams: TeamsReducer,
  livesession: LiveReducer,
  categoryReducer: CategoryReducer,
});

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "abc",
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const middleware = [thunk];

const persistedReducer = persistReducer(persistConfig, reducers);

export let store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
export let persistor = persistStore(store);

//const store = createStore(rootReducers);
