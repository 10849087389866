import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  removeLiveSessionSingle,
  removeMulitipleLiveSession,
} from "../../../../store/actions/liveCourse";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  AddActiveDate,
  addChapterAction,
  AddDripBy,
  AddDripByDate,
  AddSectionName,
  expand,
  removeSection,
} from "../../../../store/actions/section";
import Course from "../../../utils/Course";
import { ShowActive } from "../../PluginsMenu/Nestable/utils";
import { ChapterItem } from "./ChapterItem";

export const LiveSessionItems = ({
  provided,
  snapshot,
  item,
  data,
  index,
  formData,
  formDataHandler,
}) => {
  const dispatch = useDispatch();
  const [copyValue, setCopyValue] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [sectionID, setSectionID] = useState(0);
  const [inputData, setInputData] = useState("");
  const [type, setType] = useState("");
  const [dripBy, setDripData] = useState("");
  const [dripData, setData] = useState({});
  const [isExpand, setIsExpand] = useState(false);
  const { chapter, isExpandedValue, section } = useSelector(
    (state) => state.section
  );
  const cId = window.location.pathname.split("/")[2];

  const removeSectionItem = (id, type) => {
    return type === "multiple-session"
      ? dispatch(removeMulitipleLiveSession(id))
      : dispatch(removeLiveSessionSingle(id));
  };

  console.log(item);

  const sectionHandler = (e, id) => {
    const sectionName = e;
    dispatch(
      AddSectionName({
        sectionId: id,
        courseId: cId,
        sectionName: sectionName,
      })
    );
  };
  const modalOpenFunction = (item) => {
    setModalOpen(true);
    setSectionID(item);
  };

  const dripDateHandler = (e) => {
    const date = e.target.value;
    setData({
      courseId: cId,
      sectionID: sectionID,
      activeDate: date,
    });
  };

  const dripByHandler = (e) => {
    setDripData(e.target.value);
    if (e.target.value === "days") {
      setType("availableAfterDays");
    } else {
      setType("availableAfterWeeks");
    }
  };
  const dripInputHandler = (e) => {
    setInputData(e.target.value);
  };
  const saveDrip = (e) => {
    const dripValue = {
      sectionID: sectionID,
      courseId: cId,
      drip: {
        [dripBy]: true,
      },
    };
    e.preventDefault();
    const activeDateForType = {
      [type]: Number(inputData),
      sectionID: sectionID,
      courseId: cId,
    };
    dispatch(AddDripByDate(activeDateForType));
    dispatch(AddActiveDate(dripData));
    dispatch(AddDripBy(dripValue));
    toast.success("Drip Added", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    if (copyValue) {
      toast.success("Copied", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setInterval(() => {
        setCopyValue(false);
      }, 2000);
    }
  }, [copyValue]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="card-background my-1 mb-3"
        ref={provided.innerRef}
        snapshot={snapshot}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div key={item.sectionID}>
          <div className="d-flex justify-content-between">
            <h3 className="d-flex justify-content-center">{item.name}</h3>
            <img
              src="https://i.pinimg.com/736x/c1/39/d3/c139d34d479a53a776f874cc718a3881.jpg"
              height={60}
              width={60}
            />
            <i
              class="fa-solid fa-trash-can"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#f44336",
                fontSize: "23px",
              }}
              onClick={() => removeSectionItem(item.id, item.sessionName)}
            ></i>
          </div>
          <div className="d-flex mt-2 mb-4">
            <input
              type="text"
              placeholder="Section"
              name="section"
              value={item.name || "Live Session "}
              // value=}
              onChange={(e) => sectionHandler(e.target.value, item.sectionID)}
            />
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btn-danger">
              <i class="fa-solid fa-info" aria-hidden="true"></i>
              <Link
                className="white-100 px-2"
                to={`/session/${item.id}/${cId}/${
                  item.sessionName ? item.sessionName : "single-session"
                }`}
              >
                Live Session {item.Startdate} - {item.start_time}
              </Link>
            </button>
            <button className="btn btn-primary mx-2">
              Copy
              <CopyToClipboard
                text={item.start_url}
                onCopy={() => setCopyValue(true)}
              >
                <i class="fas fa-copy mx-1"></i>
              </CopyToClipboard>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
