import React, { useEffect, useState } from 'react'
import { SiSkillshare } from 'react-icons/si';
import { Form } from 'react-bootstrap'
import { MdDelete } from 'react-icons/md';
import { createskill, getAllkills, getAllSkills } from '../../services/apis';
import { toast } from 'react-toastify';

function Interviewstep2({ interviewState, setinterviewState }) {
    const [skillField, setSkillField] = useState("");
    const [skillSuggestions, setskillSuggestions] = useState([])
    const handleAddStudents = (e, skill) => {
        e.preventDefault()
        setinterviewState({ ...interviewState, skills: [...interviewState.skills, skill] });
        setSkillField('')
        setskillSuggestions([])
    };

    const handleRemoveGmail = (e, index) => {
        e.preventDefault()
        const updatedDocument = [...interviewState.skills];
        updatedDocument.splice(index, 1);
        setinterviewState({ ...interviewState, skills: updatedDocument })
    };
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchSkills();
        }, 600); // Adjust debounce time as necessary (e.g., 300ms)

        return () => clearTimeout(timeoutId); // Cleanup on unmount or input change
    }, [skillField])

    // const fetchSkills = async () => {
    //     try {
    //         // const res = await getAllkills()
    //         console.log(res)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const fetchSkills = async () => {
        if (skillField.length > 1) {
            try {
                const response = await getAllSkills(skillField)
                console.log(response)
                setskillSuggestions(response.data.data)
            } catch (error) {
                console.error("Error fetching skills:", error);
            }
        } else {
            // setSuggestions([]);
        }
    };
    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            if (!skillSuggestions.some(skill => skill.skills.toLowerCase() === skillField.toLowerCase())) {
                try {
                    const payload = {
                        skillType: "User Specified",
                        skills: skillField,
                        creatorid: localStorage.getItem("userId")
                    }
                    setinterviewState({ ...interviewState, skills: [...interviewState.skills, payload] });
                    const response = await createskill(payload)

                    setSkillField("")
                    toast.success("Skill Created")
                    // handleSelectSkill(response.data); // Add the new skill to selected skills
                } catch (error) {
                    console.error('Error adding skill:', error);
                }
            }
        }
    };
    return (
        <div>
            <h5>Add Skills * (Select from the  left skills tab)</h5>
            <div className=' rounded-2 mt-3'>
                <Form.Group className="mb-3 mt-3" controlId="exampleForm. ControlInput1">
                    {/* <Form.Label>Add Skills</Form.Label> */}
                    <div className="border border-dark rounded-2 p-2">
                        <div className="mb-3  d-flex flex-wrap gap-3" >
                            {
                                interviewState?.skills?.map((item, index) => {
                                    return <span className="border border-dark  mb-1 rounded-2 p-2 " > <strong>{item?.skills}</strong> <button className="bg-danger rounded p-2"><MdDelete fontSize={20} onClick={(e) => handleRemoveGmail(e, index)} className="text-white" /></button></span>
                                })
                            }
                        </div>
                        <div>
                            list of skills
                        </div>

                        <Form.Group className=" mt-2" controlId="formBasicPassword">
                            {/* <select className="form-select form-select-lg border border-dark" aria-label="Default select example" name='jobsubtype' onChange={handleChange}>
                                <option selected disabled>{!!interviewState?.jobsubtype ? interviewState?.jobsubtype : "Select Subtype"}</option>
                                <option value="Hybrid">Hybrid</option>
                                <option value="Remote">Remote</option>
                                <option value="Onsite">Onsite</option>
                            </select> */}

                            <Form.Control type="text" placeholder="Add upto 20 Skills" className='border-dark border' onChange={(e) => setSkillField(e.target.value)} value={skillField} onKeyDown={handleKeyDown} />
                            {/* <button className="common-btn px-3 mt-3 p-1 rounded-2" onClick={handleAddStudents}>Add</button> */}
                        </Form.Group>
                        {
                            !!skillField &&
                            <div className='p-2 px-4 border rounded-2 mt-2 border-dark'>
                                <ul>
                                    {
                                        skillSuggestions?.length === 0 ? "No such skills Present press enter to add this skill" :
                                            skillSuggestions?.map((item, index) => {
                                                return <li onClick={(e) => handleAddStudents(e, item)} style={{ cursor: "pointer" }} className='mb-2 border-bottom  pb-2 border-dark'>{item?.skills}</li>
                                            })
                                    }

                                </ul>
                            </div>
                        }
                    </div>
                </Form.Group>
            </div>
            <div className='d-flex justify-content-end'>

            </div>
        </div>
    )
}

export default Interviewstep2
