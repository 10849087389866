import React, { useEffect, useMemo, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import { getSupport } from "../../../services/PostsService";
import { CAREER, SUPPORT } from "../table/FilteringTable/Columns";
import { GlobalFilter } from "../table/FilteringTable/GlobalFilter";
import CommonTable from "../../../Common/Table/CommonTable";
import moment from "moment";

export const Support = () => {
  const [support, setSupport] = useState([]);
  const columns = useMemo(() => SUPPORT, []);
  const data = support;
  useEffect(() => {
    const fetchSupport = async () => {
      try {
        const { data } = await getSupport();
        setSupport(data?.result);
      } catch (err) {
        alert(err.message);
      }
    };
    fetchSupport();
    return <></>;
  }, []);



  const csvData = support?.map((row, index) => ({
    "S No.": index + 1,
    // "Course Name": row.course,
    "Name": row.name,
    "Email": row.email,
    "Date": moment(row.createdAt).format('DD-MM-YYYY'),
    "Phone No.": row.phone,
    "Message": row.message
  }));


  const [searchQuery, setsearchQuery] = useState('')

  const [FilteredData, setFilteredData] = useState([])
  useEffect(() => {
    handleSearchChange()
  }, [searchQuery, support]);

  const handleSearchChange = async (e) => {
    if (searchQuery === '') {
      setFilteredData(support);
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filteredResults = support.filter(item =>
        Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredData(filteredResults);
    }
  }

  return (
    <div>
      <CommonTable columns={SUPPORT} data={FilteredData} setsearchQuery={setsearchQuery} csvData={csvData} csvName={"Support-Data"} />
    </div>
  );
};
