import React, { useState } from "react";
import { Box, Select, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { saveMultipleSession } from "../../../../../../store/actions/liveCourse";
import { toast } from "react-toastify";

const ScheduleItemCard = ({ i, removeLiveSessions }) => {
  const [image1, setImage1] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    image: "",
    name: "",
    Startdate: "",
    start_time: "",
    endDate: "",
    endTime: "",
  });

  const saveData = (id) => {
    dispatch(
      saveMultipleSession({
        id: id,
        data: {
          image: image1,
          name: data.name || i.name,
          Startdate: data.Startdate || i.Startdate,
          start_time: data.start_time || i.start_time,
          endDate: data.endDate || i.endDate,
          endTime: data.endTime || i.end_time,
        },
      })
    );
    toast.success(i.name + " has been added to the schedule", {
      position: "bottom-center",
    });
  };
  const handleChange1 = (e) => {
    setLoading(true);
    const rootUrl =
      process.env.NODE_ENV === "production"
        ? "https://uploader.viosa.in/api"
        : "https://uploader.viosa.in/api";
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    fetch(`${rootUrl}/v2/upload/other`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((resp) => {
        setImage1(resp.link);
        setLoading(false);
        setData({ ...data, image: resp.link });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const inputHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <div className="schedule-card card-2 mb-4">
        <Box display="flex" flexDirection="column" m={10}>
          <div className="d-flex">
            <div className="to-column">
              {loading ? (
                <div id="cover-spin"></div>
              ) : image1 ? (
                <img
                  id="uloaded-image"
                  style={{ width: "275px", height: "225px" }}
                  src={image1 || i?.image}
                  alt=""
                />
              ) : (
                <img
                  id="uloaded-image"
                  style={{ width: "275px", height: "225px" }}
                  src="https://play-lh.googleusercontent.com/-Kd31zu1MYti5qi5HjTwYCMRhb-f4JyLd92lNhaGYyiOu_Of1jd6w462330C9krmTuo"
                  alt=""
                />
              )}

              <div>
                <button className="mt-3 mb-4 select-button">
                  <input onChange={handleChange1} type="file" />
                </button>
              </div>
            </div>

            <div style={{ width: "100%", marginLeft: "30px" }}>
              <div className="d-flex justify-content-end">
                <i
                  class="fa-solid fa-trash-can"
                  aria-hidden="true"
                  style={{
                    cursor: "pointer",
                    color: "#f44336",
                    fontSize: "23px",
                  }}
                  onClick={() => removeLiveSessions(i.id)}
                ></i>
              </div>
              <TextField
                id="time"
                label="Session Name"
                type="text"
                name="name"
                value={data.name || i?.name || ""}
                onChange={inputHandler}
                style={{ marginRight: 20 }}
                // className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className="d-flex justify-content-between mt-4">
                <TextField
                  id="time"
                  label="Start Date"
                  type="date"
                  value={data.Startdate || i?.Startdate || ""}
                  name="Startdate"
                  defaultValue="00:00:00"
                  onChange={inputHandler}
                  style={{ marginRight: 20 }}
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="time"
                  label="Start Time"
                  type="time"
                  onChange={inputHandler}
                  name="start_time"
                  value={data.start_time || i?.start_time || ""}
                  defaultValue="00:00:00"
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="d-flex justify-content-between mt-4">
                <TextField
                  id="time"
                  label="End Date"
                  type="date"
                  defaultValue="00:00:00"
                  style={{ marginRight: 20 }}
                  name="endDate"
                  value={data.endDate || i?.endDate || ""}
                  onChange={inputHandler}
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="time"
                  label="End Time"
                  name="endTime"
                  type="time"
                  value={data.endTime || i?.endTime || ""}
                  onChange={inputHandler}
                  defaultValue="00:00:00"
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
        </Box>
        <div className="d-flex justify-content-end flex-end">
          <button className="save-button" onClick={() => saveData(i?.id)}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleItemCard;
