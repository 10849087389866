import React, { useMemo, useState, useEffect } from "react";
// import PageTitle from "../../../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
// import MOCK_DATA from "./MOCK_DATA_2.json";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { UploadForm } from "./UploadForm";
import { COLUMNS } from "./Columns";
import { Link, Redirect } from "react-router-dom";
import { MdDeleteOutline, MdEdit } from "react-icons/md";

import { GlobalFilter } from "../FilteringTable/GlobalFilter";
import {
  getCourses,
  createCourses,
  getSingleCourse,
  getCoursesList,
  addBundle,
  getAllBundles,
  getAllSessions,
  getPublishedCourse,
  getUnPublishedCourse,
} from "../../../../services/PostsService";
import { Show } from "../../show";
import { ShowActive } from "../../PluginsMenu/Nestable/utils";
import "./course.css";
import { addCourse, allCourseList } from "../../../../store/actions/course";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

import CopyToClipboard from "react-copy-to-clipboard";
import Multiselect from "multiselect-react-dropdown";
import { multipleSession } from "../../../../store/actions/liveCourse";
import { deleteCourseAPI } from "../../../../services/apis";
import DeleteModal from "../../../../Common/Components/DeleteModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const CourseTable = (props) => {
  const [course, setCourse] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [bundleList, setBundleList] = useState([]);
  const [largeModal, setLargeModal] = useState(false);
  const [largeModal1, setLargeModal1] = useState(false);
  const [update, setUpdate] = useState(false);
  const [cover, setCover] = useState("");
  const { courses } = useSelector((state) => state.courses);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnailLink, setThumbNailLink] = useState("");

  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState([]);
  const [publishState, setpublishState] = useState(true)
  const { singleSessionList, multipleSessionList } = useSelector(
    (state) => state.livesession
  );
  const navigate = useHistory()
  console.log(multipleSessionList);
  // useEffect(() => {
  //   const fetch = async () => {
  //     const data = await getAllSessions();
  //     setSession(data.data.result);
  //   };
  //   fetch();
  //   return <></>;
  // }, []);

  const deleteCourse = () => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this course !",
      icon: "warning",
      buttons: ["No, cancel it!", "Yes, I am sure!"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Deleted!",
          text: "Course is deleted successfully !",
          icon: "success",
        }).then(function () {
          form.submit();
        });
      } else {
        swal("Cancelled", "Your course is safe :)", "error");
      }
    });
  };

  const [copyValue, setCopyValue] = useState(false);

  const dispatch = useDispatch();

  const [input, setInput] = useState({
    name: "",
    bundleName: "",
    description: "",
    courseContents: "",
    prevPrice: "",
    newPrice: "",
    reviewStars: "",
    about: "",
    thumnailUrl: "",
    resources: "",
    isTool: false,
  });
  useEffect(() => {
    if (cover) {
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      const formData = new FormData();
      formData.append("file", cover);
      setIsLoading(true);

      fetch(`${rootUrl}/v2/upload/other`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setThumbNailLink(data.link);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [cover?.lastModified]);

  const ChangeHandler = (e) => {
    const { name } = e.target;
    setInput({ ...input, [name]: e.target.value });
  };

  const AddBundle = async () => {
    const modifyoptions = options.map((option) => ({
      courseName: option.split("_")[0],
      courseId: option.split("_")[1],
    }));
    const payload = {
      name: input.bundleName,
      price: input.price,
      description: input.description,
      thumnailUrl: thumbnailLink,
      courseIds: modifyoptions,
      isTool: input.isTool,
    };

    const response = await addBundle(payload);
    setLargeModal1(false);
    toast.success(`Bundle Added Successfully`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const PostCourse = async () => {
    if (!input.name) {
      toast.error("Please Add Course Name")
      return;
    }
    const payload = {
      name: input.name,
      isTool: input.isTool,
    };

    await createCourses(payload).then((response) => {
      setCourseList(response?.data?.courses);
      const courseID =
        response.data.courses[response.data.courses.length - 1].course_id;
      // console.log(courseID);
      const courseName =
        response.data.courses[response.data.courses.length - 1].name;
      localStorage.setItem("courseName", courseName);
      props.history.push(`/course/${courseID}`);
    });
  };
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await getAllBundles();
      setBundleList(response.data.newBundle);
      if (publishState) {
        await getPublishedCourse().then((response) => {
          setCourseList(response.data.data);
          console.log(response)
          // dispatch(allCourseList(response?.data?.courses || []));
        });
      } else {
        await getUnPublishedCourse().then((response) => {
          setCourseList(response.data.data);
          console.log(response)
          // dispatch(allCourseList(response?.data?.courses || []));
        });
      }
    };
    fetchCourses();
  }, [selected, publishState]);

  useEffect(() => {
    if (update) {
      function getCourseAction() {
        getCourses().then((response) => {
          setCourse(response?.data?.courses);
        });
      }
      getCourseAction();
      return <></>;
    }
    setUpdate(false);
  }, [update]);
  // useEffect(() => {
  //   function getCourseAction() {
  //     getCourses().then((response) => {
  //       setCourse(response?.data?.courses);
  //     });
  //   }
  //   getCourseAction();
  //   return <></>;
  // }, []);

  const list = [
    {
      id: 1,
      title: "Courses",
    },
    // {
    //   id: 2,
    //   title: "Live Session",
    // },
    {
      id: 3,
      title: "Bundles",
    },
  ];

  const handleDeleteCourse = async (id) => {
    try {
      // console.log(id)
      await deleteCourseAPI(id)
      const data = courseList.filter((item => item._id !== id))
      setCourseList(data)
      toast.success("Course Deleted")

    } catch (error) {
      console.log(error)
    }
  }



  // const  showDelete, handleDeleteDecision, field, HideDeleteModal
  const [showDelete, setshowDelete] = useState(false)
  const [deleteCourseData, setdeleteCourseData] = useState(null)
  const handleshowDelete = () => {
    setshowDelete(true)
  }
  const HideDeleteModal = () => {
    setshowDelete(false)
  }
  const handleDeleteDecision = (value) => {
    if (value) {
      handleDeleteCourse(deleteCourseData?._id)
    } else {
      toast.error("No Course Deleted")
    }
  }





  const getRendered = (state) => {
    const Screen = {
      1: (
        <Show condition={show}>

          <div className="d-flex ">
            <div className="card-body">
              <div className="table-responsive">
                <span style={{ float: "right" }}>
                  <Button
                    className=""
                    variant="primary"
                    onClick={() => setLargeModal(true)}
                  >
                    + Add New Course
                  </Button>
                </span>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <span style={{ float: "right" }}>
                  <Button
                    className=""
                    variant="secondary"
                    onClick={() => setLargeModal1(true)}
                  >
                    + Add Course Bundles
                  </Button>
                </span>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <span style={{ float: "right" }}>
                  <Button className="" variant="success">
                    <Link to="category" className="white-100">
                      + Add New Category
                    </Link>
                  </Button>
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex gap-5 my-4 border-bottom pb-3 border-dark">

            <button
              className={`${publishState ? "common-btn" : ""} p-2 px-3 rounded`}
              onClick={() => setpublishState(true)}
            >
              Published
            </button>
            <button
              onClick={() => setpublishState(false)}
              className={`${!publishState ? "common-btn" : ""} p-2 px-3 rounded`}
            >
              UnPublished
            </button>
          </div>

          <div
            className="d-flex justify-content-evenly"
            style={{ flexWrap: "wrap" }}
          >
            {courseList?.map((course, index) => (
              <div class="col-lg-3  mx-1 col-md-6 col-sm-12 pt-4 my-3 mt-2">
                <div class="card border-1 card-product" data-count="4">
                  <div
                    class="card-header card-header-image"
                    data-header-animation="true"
                  >
                    <img
                      class="img-course"
                      src={
                        course.thumbnail ||
                        "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                      }
                    />
                  </div>
                  <div class="card-body px-0">
                    <div class="card-actions text-center">
                      <button
                        type="button"
                        className="common-btn rounded-2 p-2"
                        onClick={() => navigate.push(`/course/${course._id}`)}
                      >
                        {/* to={`course/${course.course_id}`} */}
                        <MdEdit fontSize={24} />

                        {/* <i class="fas fa-edit white-100"></i> */}
                      </button>
                      <button className="ms-3 rounded-2 p-2" onClick={() => {
                        // handleDeleteCourse(course?.course_id
                        setdeleteCourseData(course)
                        setshowDelete(true)
                      }}>
                        <MdDeleteOutline fontSize={24} />

                      </button>
                    </div>
                    <h6 class="card-title text-center pt-2">
                      <a>{course.name}</a>
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Show>
      ),
      2: (
        <Show condition={show}>
          <div className="card-header">
            <h4 className="card-title">All Live Sessions</h4>
          </div>
          <div
            className="d-flex justify-content-evenly"
            style={{ flexWrap: "wrap" }}
          >
            {singleSessionList?.map((live, index) => (
              <div class="col-lg-3 mx-1 col-md-6 col-sm-12 pt-4">
                <div class="card card-product" data-count="4">
                  <div
                    class="card-header card-header-image"
                    data-header-animation="true"
                  >
                    <img
                      class="img-course"
                      src={
                        live.image ||
                        "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                      }
                    />
                  </div>
                  <div class="card-body">
                    <div class="card-actions text-center">
                      <h4 class="card-title text-center my-2">
                        <a>{live.topic || `Live Session ${index + 1}`}</a>
                      </h4>
                      <button
                        type="button"
                        class="btn btn-danger btn-link fix-broken-card"
                      >
                        <Link
                          to={`session/${live.id}/${live.courseId}/single-session`}
                        >
                          <i class="fas fa-edit white-100"></i>
                        </Link>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-link fix-broken-card mx-2 "
                      >
                        <CopyToClipboard
                          text={live.start_url}
                          onCopy={() => setCopyValue(true)}
                        >
                          <i class="fas fa-copy white-100"></i>
                        </CopyToClipboard>
                      </button>
                      {copyValue ? (
                        <span style={{ color: "red" }}>Copied.</span>
                      ) : null}
                    </div>
                  </div>
                  <hr />
                  <div class="card-body">
                    <div class="card-actions text-center">
                      <button
                        type="button"
                        class="btn btn-danger btn-link fix-broken-card"
                      >
                        <a
                          className="white-100"
                          href={live.start_url}
                          target="_blank"
                          to={`course/${live.id}`}
                        >
                          Go Live
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {session?.map((live, index) => (
              <div class="col-lg-3 mx-1 col-md-6 col-sm-12 pt-4">
                <div class="card card-product" data-count="4">
                  <div
                    class="card-header card-header-image"
                    data-header-animation="true"
                  >
                    <img
                      class="img-course"
                      src={
                        live.image ||
                        "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                      }
                    />
                  </div>
                  <div class="card-body">
                    <div class="card-actions text-center">
                      <h4 class="card-title text-center my-2">
                        <a>{live.data.name || `Live Session ${index + 1}`}</a>
                      </h4>
                      <button
                        type="button"
                        class="btn btn-danger btn-link fix-broken-card"
                      >
                        <Link
                          to={`session/${live.id}/${live.courseId}/single-session`}
                        >
                          <i class="fas fa-edit white-100"></i>
                        </Link>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-link fix-broken-card mx-2 "
                      >
                        <CopyToClipboard
                          text={live.start_url}
                          onCopy={() => setCopyValue(true)}
                        >
                          <i class="fas fa-copy white-100"></i>
                        </CopyToClipboard>
                      </button>
                      {copyValue ? (
                        <span style={{ color: "red" }}>Copied.</span>
                      ) : null}
                    </div>
                  </div>
                  <hr />
                  <div class="card-body">
                    <div class="card-actions text-center">
                      <button
                        type="button"
                        class="btn btn-danger btn-link fix-broken-card"
                      >
                        <a
                          className="white-100"
                          href={live.start_url}
                          target="_blank"
                          to={`course/${live.id}`}
                        >
                          Go Live
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Show>
      ),
      3: (
        <Show condition={show}>
          <div className="card-header">
            <h4 className="card-title">All Bundles</h4>
          </div>

          <div
            className="d-flex justify-content-evenly"
            style={{ flexWrap: "wrap" }}
          >
            {bundleList?.map((course, index) => (
              <div class="col-lg-3 mx-1 col-md-6 col-sm-12 pt-4 my-3 mt-2">
                <div class="card border-1 card-product" data-count="4">
                  <div
                    class="card-header card-header-image"
                    data-header-animation="true"
                  >
                    <img
                      class="img-course"
                      src={
                        course.thumnailUrl ||
                        "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                      }
                    />
                  </div>
                  <div class="card-body px-0">
                    <div class="card-actions text-center">
                      <button
                        type="button"
                        class="btn  w-100 btn-danger btn-link white-100 fix-broken-card"
                      >
                        {course.price || ""}
                      </button>
                    </div>
                    <h6 class="card-title text-center pt-2">
                      <a>{course.name || "Bundle"}</a>
                    </h6>
                    <p class="text-center pt-2">
                      <p>{course.description || "Bundle"}</p>
                    </p>
                    <button
                      type="button"
                      class="btn mx-auto w-50 btn-secondary btn-link white-100 fix-broken-card"
                    >
                      <Link
                        className="white-100"
                        to={`/bundle/${course._id}/${course.name}`}
                      >
                        <i class="fa fa-pencil"></i>
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Show>
      ),
    };
    if (!Screen[state]) return null;
    return Screen[state];
  };


  return (
    <>

      <div className="card-3">
        <div>
          <div className="tabbutton mt-5 pt-2" id="tabbutton">
            <ul className="d-flex justify-content-evenly">
              {list.map((item) => (
                <ShowActive
                  title={item.title}
                  className="tabbutton"
                  active={selected === item.id}
                  setSelected={setSelected}
                  id={item.id}
                />
              ))}
            </ul>
          </div>
          <div className="container">{getRendered(selected)}</div>
          {/* add course modal */}
          <Modal
            className="fade bd-example-modal-md"
            show={largeModal}
            size="md"
          >
            <Modal.Header>
              <Modal.Title>Add Course</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setLargeModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Course Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={input.name}
                        onChange={ChangeHandler}
                        name="name"
                        placeholder="Enter Course name..."
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex align-items-center justify-content-center">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onClick={(e) =>
                        setInput({ ...input, isTool: e.target.checked })
                      }
                    />
                    <h6 className="mt-2 pt-1 mx-2">Add as a Tool</h6>
                  </div>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                variant=""
                type="button"
                onClick={PostCourse}
                className="btn btn-primary"
              >
                Add
              </Button>
              <Button
                variant="danger light"
                onClick={() => setLargeModal(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* TODO:bundle Modal */}
          <Modal
            className="fade bd-example-modal-md"
            show={largeModal1}
            size="md"
          >
            <Modal.Header>
              <Modal.Title>Add Course To Bundle</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setLargeModal1(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col className="col-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bundle Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={input.bundleName}
                        onChange={ChangeHandler}
                        name="bundleName"
                        placeholder="Enter Bundle name..."
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bundle Description</Form.Label>
                      <Form.Control
                        type="text"
                        value={input.description}
                        onChange={ChangeHandler}
                        name="description"
                        placeholder="Enter Bundle Description..."
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bundle Price</Form.Label>
                      <Form.Control
                        type="number"
                        value={input.price}
                        onChange={ChangeHandler}
                        name="price"
                        placeholder="Enter Bundle Price..."
                      />
                    </Form.Group>
                  </Col>
                  {isLoading && <div id="cover-spin"></div>}
                  {thumbnailLink && (
                    <img
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                      src={thumbnailLink}
                      className="mb-3"
                      onError={(e) => {
                        e.target.src = defualtImg;
                        e.onerror = null;
                      }}
                    />
                  )}
                  <UploadForm
                    className="mt-3"
                    isImage={true}
                    isPreview={false}
                    cover={cover}
                    setCover={setCover}
                  />
                  <Col className="col-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select Course</Form.Label>
                      <Multiselect
                        options={courses.map(
                          (item) => item.name + "_" + item.course_id
                        )}
                        isObject={false}
                        onSelect={(e) => setOptions(e)}
                        onRemove={(e) => console.log(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                variant=""
                type="button"
                onClick={AddBundle}
                className="btn btn-primary"
              >
                Add Bundle
              </Button>
              <Button
                variant="danger light"
                onClick={() => setLargeModal1(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* view course modal */}
        </div>
        <DeleteModal handleDeleteDecision={handleDeleteDecision} text={`Do you want to delete ${deleteCourseData?.name} ?`} showDelete={showDelete} HideDeleteModal={HideDeleteModal} />

      </div>
    </>
  );
};
export default CourseTable;
