import React, { useState } from "react";
import { ShowActive } from "../PluginsMenu/Nestable/utils";
import { Career } from "./Career";
import { Collab } from "./Collab";
import { Support } from "./Support";

export const Query = () => {
  const [selected, setSelected] = useState(1);
  const list = [
    {
      id: 1,
      title: "Career Counselling",
    },
    {
      id: 2,
      title: "Get Support",
    },
    {
      id: 3,
      title: "Collaboration",
    },
  ];

  const getRendered = (state) => {
    const Screen = {
      1: <Career />,
      2: <Support />,
      3: <Collab />,
    };
    if (!Screen[state]) return null;
    return Screen[state];
  };
  return (
    <div className="card-3">
      <div>
        <div className="tabbutton mt-5 pt-2" id="tabbutton">
          <ul className="d-flex justify-content-evenly">
            {list.map((item) => (
              <ShowActive
                title={item.title}
                className="tabbutton"
                active={selected === item.id}
                setSelected={setSelected}
                id={item.id}
              />
            ))}
          </ul>
        </div>
        <div className="container">{getRendered(selected)}</div>
      </div>
    </div>
  );
};
