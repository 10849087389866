import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVideo, otherFileLink } from "../../../../../store/actions/section";
import DropFile from "../../../AppsMenu/Email/Compose/DropFile";
import { UploadForm } from "../UploadForm";

export const Video = ({ chapterID, sectionID, videoLINK, otherLink }) => {
  const [cover, setCover] = useState("");
  const [video, setVideo] = useState("");
  const [isVideo, setIsVideo] = useState(true);
  const [videoLink, setVideoLink] = useState("");
  const [otherFile, setOtherFile] = useState(true);
  const [file, setFile] = useState("");
  const [percentage, setPercentage] = useState(null);
  const [loading, setLoading] = useState(null);
  const [loading1, setLoading1] = useState(null);
  const cId = window.location.pathname.split("/")[2];
  const dispatch = useDispatch();
  useEffect(() => {
    const rootUrl =
      process.env.NODE_ENV === "production"
        ? "https://uploader.viosa.in/api"
        : "https://uploader.viosa.in/api";
    if (video) {
      setLoading(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            // setLoading(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", video);
      //test
      axios
        .post(`${rootUrl}/v2/upload/file`, formData, config)
        .then((data) => {
          setLoading(false);
          setVideoLink(data.data.link);
          dispatch(
            addVideo({
              courseId: cId,
              sectionID: sectionID,
              chapterID: chapterID,
              videoLink: data.data.link,
            })
          );
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      setLoading1(true);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      fetch(`${rootUrl}/v2/upload/other`, {
        method: "POST",
        body: formData,
        onUploadProgress: (p) => {
          setPercentage(p);
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setVideoLink(data.link);
          dispatch(
            otherFileLink({
              courseId: cId,
              sectionID: sectionID,
              chapterID: chapterID,
              otherLink: data.link,
            })
          );
          setLoading1(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading1(false);
        });
    }
  }, [video?.lastModified, file?.lastModified]);
  const updatedQuery = new Date();
  const vidLink = useSelector((state) => state.section);
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className="mb-2">Add mp4,wmv,avi,mkv or flv video file</div>
        <div>
          <UploadForm
            className="mt-3"
            cover={cover}
            videoLink={videoLink}
            setCover={setCover}
            video={video}
            isVideo={isVideo}
            setVideo={setVideo}
          />
        </div>{" "}
        {loading ? (
          <div className="d-flex">
            <label for="progress-bar">{percentage}%</label>
            <progress className="progress-bar" value={percentage} max="100" />
          </div>
        ) : (
          ""
        )}
        {videoLINK && (
          <video key={videoLINK} width="50%" id="video" height="200" controls>
            <source src={`${videoLINK}?${updatedQuery}`} />
          </video>
        )}
      </div>
      <hr />
      <div className="d-flex justify-content-center">
        <UploadForm
          className="mt-2"
          setFile={setFile}
          file={file}
          fileName="ADD DOWNLOADABLE FILE"
          otherFile={otherFile}
        />
      </div>
      <div className="d-flex justify-content-center">
        {loading1 ? "Uploading..." : ""}
      </div>
      <div className="d-flex justify-content-center">
        {!loading1 &&
          (file.name || decodeURI(otherLink?.split("/")[3] || "") || "")}
      </div>
      {/* {otherLink && (
        <div key={otherLink} className="d-flex justify-content-center"></div>
      )} */}
      <hr />
    </div>
  );
};
