import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  AddCourseToTeam,
  addIndividualInTeam,
  AddUsersInTeam,
  assingCourse,
  createTeam,
  getCoursesList,
  getIndividualTeam,
  getOrders,
  getSingleUser,
  getTeamList,
  getUsers,
  UpdateUserInfo,
} from "../../../services/PostsService";
import "./userdetails.css";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { allCourseList } from "../../../store/actions/course";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Show } from "../show";
import { allTeamsList } from "../../../store/actions/teams";
import { Dateformat } from "../PluginsMenu/Nestable/utils";
import { toast, ToastContainer } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "material-ui/styles";
import {
  Checkbox,
  ListItemText,
  OutlinedInput,
  Select,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { MenuItem } from "material-ui";
import { AssignTeamToUser, getAllColleges, getBatchesByCollegeId } from "../../../services/apis";

const UserDetails = () => {
  const [courseList, setCourseList] = useState([]);
  const [newTeam, setNewTeam] = useState("");
  const [teamName, setTeamName] = useState("");
  const [users, setUsers] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [addusermodal, setAddusermodal] = useState(false);
  const [addCoursemodal, setAddCourseModal] = useState(false);
  const [userTeams, setuserTeams] = useState([])
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [options, setOptions] = useState([]);
  const [isShowTeam, setIsShowTeam] = useState(false);
  const dispatch = useDispatch();
  const [courseModalOpen, setCourseModalOpened] = useState(false);
  const [user, setUser] = useState([]);
  const params = useParams();
  const [orders, setOrders] = useState([]);
  const [userpage, setuserpage] = useState(1);
  const [teamId, setTeamId] = useState("");
  const [input, setInput] = useState({
    courseselected: [],
    userselected: [],
  });

  const handleChangeCoupon = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  useEffect(() => {
    if (params.id && user[0]?.course?.length) {
      setInput({
        ...input,
        courseselected: user[0]?.course.map((item) => item.courseID),
      });
    }
  }, [params.id, user[0]?.course]);
  const [updateDetails, setUpdateDetails] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
  });

  const UpdateDetails = (e) => {
    setUpdateDetails({ ...updateDetails, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await getSingleUser(params.id);
      setUser(res.data.result);
    };
    fetch();
  }, []);
  useEffect(() => {
    const fetchCourses = async () => {
      await getCoursesList().then((response) => {
        setCourseList(response.data.courses);
        dispatch(allCourseList(response?.data?.courses || []));
      });
    };
    fetchCourses();
  }, []);
  useEffect(() => {
    const fetchTeams = async () => {
      await getTeamList().then((response) => {
        setTeamList(response?.data?.result || []);
        dispatch(allTeamsList(response?.data.result || []));
      });
    };
    fetchTeams();
  }, [addusermodal, isShowTeam]);
  const hideModal = () => {
    setAddusermodal(false);
    setIsShowTeam(false);
    setAddCourseModal(false);
  };
  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const formData = {
      teamName: newTeam,
    };
    console.log(formData);

    const data = await createTeam(formData);
    toast.success("Team Created Successfully");
    setIsShowTeam(false);
    // hideModal();
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getUsers();
        setUsers(response.data.result);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
    const fetch2 = async () => {
      const response = await getOrders();
      setOrders(response.data.result);
    };
    fetch2();
    return <></>;
  }, []);

  //TODO:filter for order

  const filterOrders = orders.filter((order) => order.userId?._id == params.id);

  const handleSubmit2 = async (e) => {
    e.preventDefault();

    const teamAddData = {
      email: user[0]?.email,
      phoneNumber: user[0]?.phoneNumber,
      name: user[0]?.name,
      teamId: teamId,
    };

    const data = await addIndividualInTeam(teamAddData);
    getUsersTeam();
    toast.success(`User have been Assigned in a Team`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    hideModal();
  };

  const teamHandler = (item) => {
    setTeamName(item?.teamName);
    setTeamId(item?._id);
  };

  const { teams } = useSelector((state) => state.teams)
  // const filterUserByTeam = teams.filter(())
  const filteredData = teamList?.map((d) =>
    d?.teamMembers?.filter((f, index) => f.userId == params.id)
  );
  const renderdata = filteredData.flat();
  const filteredTeamMember = teamList?.filter((d) => d._id == teamId);
  const filterteam = filteredTeamMember[0]?.teamMembers?.map((d) => d.userId);

  const updateInfo = async (e) => {
    e.preventDefault();
    const formData = {
      userId: params.id,
      name: updateDetails.name || user[0]?.name,
      email: updateDetails.email || user[0]?.email,
      password: updateDetails.password,
      phoneNumber: updateDetails.phoneNumber || user[0].phoneNumber,
    };

    const data = await UpdateUserInfo(formData);
    toast.success(`User Updated Successfully`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSubmit3 = async (e) => {
    e.preventDefault();

    const modifyCourses = selectedCourses.map((course) => ({
      courseId: course.split("_")[1],
    }));
    const formData = {
      courseId: modifyCourses,
      teamMember: filterteam,
    };
    const data = await AddCourseToTeam(formData);
    toast.success(`course have been Assigned  ${modifyCourses?.length} in `, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    hideModal();
  };

  const assignthesecourses = async () => {
    try {
      const courseIds = input.courseselected.filter(
        (item, index) => item !== user[0]?.course.map((d) => d.courseID)[index]
      );
      const body = {
        userId: params.id,
        courseIdBody: courseIds,
      };
      if (courseIds.length === 0) {
        alert("Please Select Mininmum 1 course");
        return;
      }
      const response = await assingCourse(body);
      toast.success("Course Assigned Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch {
      toast.error(`Failed to assign courses to user`);
      return;
    }
  };


  const getUsersTeam = async (email) => {
    try {
      const res = await getIndividualTeam({ email: email });
      console.log(res, "team")
      setuserTeams(res?.data?.data?.teams || []);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    if (user && user.length > 0) {
      getUsersTeam(user[0]?.email);
    }
  }, [user])



  //assigning team
  const [collegeData, setcollegeData] = useState([])
  const [teamData, setteamData] = useState([])
  const [assignTeamObj, setassignTeamObj] = useState({
    id: params.id,
    collegeId: "",
    teamId: ""
  })


  const fetchCollegeData = async () => {
    try {
      const res = await getAllColleges()
      // console.log(res)
      setcollegeData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchTeamData = async () => {
    try {
      const res = await getBatchesByCollegeId(assignTeamObj.collegeId)
      setteamData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchCollegeData()
  }, [])

  useEffect(() => {
    if (assignTeamObj?.collegeId) {
      fetchTeamData()
    }
  }, [assignTeamObj?.collegeId])


  const postAssignTeam = async (e) => {
    e.preventDefault()
    try {
      const payload = {
        id: params.id,
        collegeId: assignTeamObj?.collegeId,
        teamId: assignTeamObj?.teamId
      }
      const res = await AssignTeamToUser(payload)
      getUsersTeam()
      hideModal()
      console.log(res)
    } catch (error) {
      console.log(error)

    }
  }


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal show={courseModalOpen} onHide={() => setCourseModalOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Courses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MuiThemeProvider>
            <div className="row">
              <div className="col">
                <FormControl variant="standard" className="coupon-form-input">
                  <FormLabel htmlFor="coupon-input">Select Course</FormLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={input?.courseselected}
                    onChange={(e) => handleChangeCoupon(e)}
                    input={<OutlinedInput label="Tag" />}
                    name="courseselected"
                    renderValue={(selected) => selected?.join(", ")}
                  >
                    {/* {courses.map((course) => course.name)} */}
                    {courseList?.map((course) => (
                      <MenuItem
                        key={course?.course_id}
                        value={course?.course_id}
                      >
                        <Checkbox
                          checked={
                            input?.courseselected?.indexOf(course?.course_id) >
                            -1
                          }
                        />
                        <ListItemText primary={course?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <Modal.Footer>
              <button
                className="btn btn-warning button"
                onClick={() => setCourseModalOpened(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => assignthesecourses()}
                className="btn btn-warning button modal-btn"
              >
                Assign Courses
              </button>
            </Modal.Footer>
          </MuiThemeProvider>
        </Modal.Body>
      </Modal>
      <Link to="/users">
        <button className="btn btn-danger shadow">
          <i class="fa-solid fa-arrow-left mx-1"></i> Back
        </button>
      </Link>
      <div className="card-3 mt-4">
        <div className="row ">
          <div className="col-xl-3 col-sm-6">
            <div className="card text-center">
              <div className="card-body">
                <img
                  className="mb-2 rounded-circle"
                  style={{ width: "100px", height: "100px" }}
                  src="https://www.w3schools.com/howto/img_avatar.png"
                  alt="userimg"
                />
                <h4 className="fw-bold">{user[0]?.name}</h4>
                <span className="fs-16 text-black">
                  Registered on {new Date(user[0]?.createdAt).toDateString()}
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 card-users-details">
            <div className="card text-center">
              <div className="card-body">
                <span className="fs-16 text-black">Courses</span>
                <h2>{user[0]?.course.length}</h2>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 card-users-details">
            <div className="card text-center">
              <div className="card-body">
                <span className="fs-16 text-black">Teams</span>
                <h2>{userTeams?.length || 0}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="card-3 text-center">
            <div className="card-body">
              <div className="row ">
                <div
                  onClick={() => {
                    setuserpage(1);
                  }}
                  className={` ${userpage === 1 ? "active" : null
                    } users-specific-details`}
                >
                  <h4>Courses</h4>
                </div>
                <div
                  onClick={() => {
                    setuserpage(2);
                  }}
                  className={` ${userpage === 2 ? "active" : null
                    } users-specific-details`}
                >
                  <h4>Teams</h4>
                </div>
                {/* <div
                onClick={() => {
                  setuserpage(3);
                }}
                className={` ${
                  userpage === 3 ? "active" : null
                } users-specific-details`}
              >
                <h4>BUNDLES</h4>
              </div> */}
                <div
                  onClick={() => {
                    setuserpage(4);
                  }}
                  className={` ${userpage === 4 ? "active" : null
                    } users-specific-details`}
                >
                  <h4>Orders</h4>
                </div>
                <div
                  onClick={() => {
                    setuserpage(5);
                  }}
                  className={` ${userpage === 5 ? "active" : null
                    } users-specific-details`}
                >
                  <h4>Settings</h4>
                </div>
                <div
                  onClick={() => {
                    setuserpage(6);
                  }}
                  className={` ${userpage === 6 ? "active" : null
                    } users-specific-details`}
                >
                  <h4>User Profile</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="card-3 mt-3 text-center">
            <div className="d-flex justify-content-end">
              {/* <button
                className="btn btn-primary"
                onClick={() => setCourseModalOpened(true)}
              >
                <i class="fa-solid fa-plus mx-1"></i> Add Course
              </button> */}
            </div>
            <div className="card-body">
              {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
              {userpage === 1 && (
                <>
                  <div className="row">
                    <h3>User Courses</h3>{" "}
                  </div>
                  <table className="table  display">
                    <thead>
                      <tr>
                        <th>Course Name</th>
                        <th>Added on</th>
                        <th>Course Watch Progress</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user[0]?.course.map((item) => (
                        <tr>
                          <td>{item?.name}</td>
                          <td>{Dateformat(item?.purchasedAt)}</td>
                          <td>
                            <div
                              className="progress"
                              style={{ height: "13px" }}
                            >
                              <div
                                className="progress-bar gradient-5 progress-animated"
                                style={{ width: "20%", height: "13px" }}
                              ></div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {userpage === 2 && (
                <>
                  <div className="d-flex  justify-content-between   align-items-center mb-3">
                    <h3>User Teams</h3>
                    <div className="d-flex gap-4">
                      <button
                        className="btn btn-primary "
                        onClick={() => setAddusermodal(true)}
                      >
                        + Assign Team
                      </button>
                      <button
                        className="btn btn-primary "
                        onClick={() => setAddCourseModal(true)}
                      >
                        Course Assign
                      </button>
                    </div>
                    {/* <show condition={setAddCourseModal}> */}
                    <Modal show={addCoursemodal} onHide={hideModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Select Course</Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        <Form onSubmit={(e) => handleSubmit3(e)}>
                          <Form.Group
                            className="mb-3 d-flex justify-content-between align-items-center "
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              <h4>Select Team</h4>
                            </Form.Label>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                                {teamName || "Team"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {teamList?.map((item) => (
                                  <Dropdown.Item
                                    onClick={() => teamHandler(item)}
                                  >
                                    {item?.teamName}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Form.Group>

                          <Multiselect
                            options={courseList?.map(
                              (item) => item?.name + "_" + item?.course_id
                            )}
                            isObject={false}
                            onSelect={(e) => setSelectedCourses(e)}
                            onRemove={(e) => console.log(e)}
                          />

                          <Modal.Footer>
                            <button
                              className="btn btn-warning button"
                              onClick={hideModal}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-warning button modal-btn"
                            >
                              Add Course
                            </button>
                          </Modal.Footer>
                        </Form>
                      </Modal.Body>
                    </Modal>
                    {/* </show> */}
                    <Modal show={addusermodal} onHide={hideModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Assign Team</Modal.Title>
                      </Modal.Header>
                      <Show condition={!isShowTeam}>
                        <Modal.Body>
                          <Form onSubmit={(e) => postAssignTeam(e)}>
                            <label htmlFor="">College</label>
                            <select className="form-select form-select-lg border border-dark" aria-label="Default select example" onChange={(e) => setassignTeamObj({ ...assignTeamObj, collegeId: e.target.value })} >
                              <option selected disabled>{!!assignTeamObj?.collegeId ? collegeData?.find((item) => item._id === assignTeamObj?.collegeId)?.collegename : "Select College"}</option>
                              {
                                collegeData?.map((item, index) => {
                                  return <option value={item._id}>{item?.collegename}</option>
                                })
                              }
                            </select>

                            <label className="mt-3" htmlFor="">Teams</label>
                            <select disabled={teamData?.length > 0 ? false : true} className="form-select form-select-lg border border-dark" aria-label="Default select example" onChange={(e) => setassignTeamObj({ ...assignTeamObj, teamId: e.target.value })} >
                              <option selected disabled>{!!assignTeamObj?.teamId ? teamData?.find((item) => item._id === assignTeamObj?.teamId)?.name : "Select Team"}</option>
                              {
                                teamData?.map((item, index) => {
                                  return <option value={item._id}>{item?.name}</option>
                                })
                              }
                            </select>
                            <Modal.Footer>
                              <button
                                className="btn btn-warning button"
                                onClick={hideModal}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn btn-warning button modal-btn"
                              >
                                Update
                              </button>
                            </Modal.Footer>
                          </Form>
                        </Modal.Body>
                      </Show>
                      <Show condition={isShowTeam}>
                        <Modal.Body>
                          <Form onSubmit={handleSubmit1}>
                            <Form.Group className="mb-3 d-flex justify-content-between align-items-center ">
                              <div>
                                <h4>Write Team Name</h4>
                                <Form.Control
                                  className="form-control"
                                  onChange={(e) => setNewTeam(e.target.value)}
                                  type="text"
                                />
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-warning button modal-btn"
                                >
                                  Create new team
                                </button>
                              </div>
                            </Form.Group>

                            <Modal.Footer>
                              <button
                                className="btn btn-warning button"
                                onClick={hideModal}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn btn-secondary button modal-btn"
                                onClick={() => setIsShowTeam(false)}
                              >
                                Select team Members
                              </button>
                            </Modal.Footer>
                          </Form>
                        </Modal.Body>
                      </Show>
                    </Modal>
                  </div>

                  <table className="table  display">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Name</th>
                        <th>Created On</th>
                        {/* <th>IsActive</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {userTeams?.map((r, index) => (
                        <>
                          <tr>
                            <td>{index + 1} </td>
                            <td>{r?.name} </td>
                            <td>{r?.createdAt?.split("T")[0]}</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {/* {userpage === 3 && (
              <>
                <div className="row">
                  <h3>Bundle Orders</h3>{" "}
                </div>
                <table className="table  display">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Bundle Name</th>
                      <th>Amount</th>
                      <th>Currency</th>
                      <th>Gateway</th>
                      <th>Order Date</th>
                      <th>Payment Date</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user[0]?.course.map((item) => {
                      <tr>
                        <td>3 </td>
                        <td>Career Map</td>
                        <td>1500</td>
                        <td>INR</td>
                        <td>Razorpay</td>
                        <td>Sep 2,2022</td>
                        <td>Sep 2,2022</td>
                        <td>Piad</td>
                        <td>
                          <Button className="mx-1">x</Button>
                        </td>
                      </tr>;
                    })}
                  </tbody>
                </table>
              </>
            )} */}
              {userpage === 4 && (
                <>
                  <div className="row">
                    <h3>Course Orders</h3>{" "}
                  </div>
                  <table className="table  display">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Course Name</th>
                        <th>Amount</th>
                        <th>Currency</th>
                        <th>Gateway</th>
                        <th>Order Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterOrders?.length > 0 ? (
                        filterOrders?.map((item, index) => (
                          <tr>
                            <td>{index + 1} </td>
                            <td>{item?.courseName}</td>
                            <td>{item?.amount}</td>
                            <td>{item?.currency}</td>
                            <td>{"Razorpay" + " " + item.razorpay_order_id}</td>
                            <td>{new Date(item?.createdAt).toDateString()}</td>
                            <td>Paid</td>
                            <td>
                              <Button className="mx-1">x</Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <h5 className="fw-bold">No Data Found</h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              {userpage === 5 && (
                <>
                  <div className="card-3 mb-2 border-1 text-center">
                    <div className="card-body">
                      <div className="row">
                        <h3>Change Name</h3>
                      </div>
                      <div className="row">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ float: "left" }}>
                            Name
                          </Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            defaultValue={user[0]?.name}
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              UpdateDetails(e);
                            }}
                            required
                            autoComplete="Name"
                          />
                        </Form.Group>
                        <Button
                          onClick={(e) => updateInfo(e)}
                          className="mx-3"
                          style={{ width: "150px" }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card-3 mb-2 border-1 text-center">
                    <div className="card-body">
                      <div className="row">
                        <h3>Change Email</h3>
                      </div>
                      <div className="row">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ float: "left" }}>
                            Email
                          </Form.Label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            defaultValue={user[0]?.email}
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              UpdateDetails(e);
                            }}
                            required
                            autoComplete="email"
                          />
                        </Form.Group>
                        <Button
                          onClick={(e) => updateInfo(e)}
                          className="mx-3"
                          style={{ width: "150px" }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card-3 mb-2 border-1 text-center">
                    <div className="card-body">
                      <div className="row">
                        <h3>Change Contact Number</h3>
                      </div>
                      <div className="row">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ float: "left" }}>
                            Phone no.
                          </Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phoneNumber"
                            defaultValue={user[0]?.phoneNumber}
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              UpdateDetails(e);
                            }}
                            required
                            min={10}
                            max={12}
                          />
                        </Form.Group>
                        <Button
                          onClick={(e) => updateInfo(e)}
                          className="mx-3"
                          style={{ width: "150px" }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card-3 mb-2 border-1 text-center">
                    <div className="card-body">
                      <div className="row">
                        <h3>Change Password</h3>
                      </div>
                      <div className="row">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ float: "left" }}>
                            Password
                          </Form.Label>
                          <input
                            type="password"
                            className="form-control"
                            defaultValue=""
                            autoComplete="false"
                            id="password"
                            name="password"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              UpdateDetails(e);
                            }}
                            required
                          />
                        </Form.Group>
                        <Button
                          onClick={(e) => updateInfo(e)}
                          className="mx-3"
                          style={{ width: "150px" }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {userpage === 6 && (
                <>
                  <div className="card-3 mb-2 border-1 text-center">
                    <div className="card-body">
                      <div className="row">
                        <h3>Education Details</h3>
                      </div>
                      <ul>
                        {user[0]?.education.map((edu) => (
                          <li key={edu.id}>
                            <strong>{edu.course}</strong> from {edu.college}
                            <small>
                              {edu.startingYear + " - " + edu.passingYear}
                            </small>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card-3 mb-2 border-1 text-center">
                    <div className="card-body">
                      <div className="row">
                        <h3>Work Experience</h3>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
