import React, { useState, useEffect, useMemo } from "react";

import { Button } from "react-bootstrap";
import {
  deleteCouponsApi,
  getCoupons,
} from "../../../../services/PostsService";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { COUPONTABLE } from "../Courses/Columns";

import { GlobalFilter } from "../FilteringTable/GlobalFilter";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import CommonTable from "../../../../Common/Table/CommonTable";
export const CouponTable = () => {
  const [coupon, setCoupon] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getCoupons();
        setCoupon(response.data.result);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  // const columns = useMemo(() => COUPONTABLE, []);
  // const data = coupon;

  // const tableInstance = useTable(
  //   {
  //     columns,
  //     data,
  //     initialState: { pageIndex: 0 },
  //   },
  //   useFilters,
  //   useGlobalFilter,
  //   usePagination
  // );

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   prepareRow,
  //   state,
  //   page,
  //   gotoPage,
  //   pageCount,
  //   pageOptions,
  //   nextPage,
  //   previousPage,
  //   canNextPage,
  //   canPreviousPage,
  //   setGlobalFilter,
  // } = tableInstance;

  // const { globalFilter, pageIndex } = state;

  const [checked, setChecked] = useState([]);
  const checkboxSelect = (e) => {
    if (e.target.checked) {
      setChecked([...checked, e.target.value]);
    }
    if (!e.target.checked) {
      setChecked(checked.filter((item) => item !== e.target.value));
    }
  };
  const deleteCoupons = (e) => {
    const deleteCoupon = checked.map(async (item) => {
      try {
        await deleteCouponsApi({
          id: item,
        });
      } catch (e) {
        console.log(e);
      }
    });
    Promise.all(deleteCoupon).then(() => {
      debugger;
      window.location.replace("/coupons");
    });
  };

  const deleteCoupon = () => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["No, cancel it!", "Yes, I am sure!"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Delete!",
          text: " Delete!",
          icon: "success",
        }).then(function () {
          deleteCoupons();
        });
      } else {
        swal("Cancelled", "Your Coupon is safe :)", "error");
      }
    });
  };



  const csvData = coupon?.map((row, index) => ({
    "S No.": index + 1,
    "Coupon Code": row.couponCode,
    "Discount Type": row?.discountType,
    "Discount Value": row.discountValue,
    "Remaining Allowed": row?.numberOfAllowed,
  }));


  const [searchQuery, setsearchQuery] = useState('')

  const [FilteredData, setFilteredData] = useState([])
  useEffect(() => {
    handleSearchChange()
  }, [searchQuery, coupon]);

  const handleSearchChange = async (e) => {
    if (searchQuery === '') {
      setFilteredData(coupon);
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filteredResults = coupon.filter(item =>
        Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredData(filteredResults);
    }
  }



  return (
    <div className="card-3">
      <div className="card-header">
        <h4 className="card-title">Coupon Management</h4>
        {checked.length > 0 && (
          <>
            <h1>{checked.length}</h1>
            <Button
              className="mt-4"
              variant="danger"
              onClick={() => deleteCoupon()}
            >
              <i class="fa-solid fa-user mx-2"></i> Delete
            </Button>
          </>
        )}
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <span style={{ float: "right" }}>
            <Button className="mt-4" variant="primary">
              <Link to="create/coupon" className="white-100">
                <i class="fa-solid fa-user mx-2"></i> Add New Coupon
              </Link>
            </Button>
          </span>
          {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
          <CommonTable data={FilteredData} csvData={csvData} csvName={"Coupon-Data"} setsearchQuery={setsearchQuery} columns={COUPONTABLE} />
          {/* <table {...getTableProps()} className="table  display">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      {column.canFilter ? column.render("Filter") : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="">
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    className="cursor-pointer active-hover active-hover-fw-bold"
                    {...row.getRowProps()}
                    // onClick={() => history.push(`/coupon/${row.original._id}`)}
                  >
                    <input
                      type="checkbox"
                      className="mt-4 mx-2 p-2 cursor-pointer"
                      onChange={checkboxSelect}
                      value={row.original._id}
                    />
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table> */}
          {/* <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
