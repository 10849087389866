import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  // baseURL: `https://api-staging.viosa.in`,
  baseURL: `https://api.viosa.in`,
  // baseURL: `http://localhost:3200`,
});

axios.defaults.headers.common["token"] = localStorage.getItem("token");

axiosInstance.interceptors.request.use((config) => {
  return config;
});

export default axiosInstance;
