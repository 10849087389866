import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ShowActive } from "../../PluginsMenu/Nestable/utils";
import { BundleCurriculam } from "./BundleCurriculam";
import { PublishBundle } from "./PublishBundle";

export const BundleMain = () => {
  const [selected, setSelected] = useState(1);

  const list = [
    {
      id: 1,
      title: "Curriculum",
    },
    {
      id: 2,
      title: "Publish",
    },
    {
      id: 3,
      title: "Landing Page",
    },
    // {
    //   id: "4",
    //   title: "Preview Course",
    // },
  ];

  const getRendered = (state) => {
    const Screen = {
      1: <BundleCurriculam />,
      2: <PublishBundle />,
    };
    if (!Screen[state]) return null;
    return Screen[state];
  };

  return (
    <>
      <div>Course {`>`}</div>
      <div className="tabbutton mt-5 pt-2" id="tabbutton">
        <ul className="d-flex justify-content-evenly">
          {list.map((item) => (
            <ShowActive
              title={item.title}
              className="tabbutton"
              active={selected === item.id}
              setSelected={setSelected}
              id={item.id}
            />
          ))}
        </ul>
        <div className="container">{getRendered(selected)}</div>
        <div className="d-flex justify-content-center">
          {selected > 1 && (
            <Button
              className="mt-3 mx-2"
              variant="danger"
              onClick={() =>
                setSelected(selected !== 1 ? selected - 1 : selected)
              }
            >
              &larr; Prev
            </Button>
          )}
          <Button
            className="mt-3"
            variant="danger"
            onClick={() =>
              setSelected(selected !== 3 ? selected + 1 : selected)
            }
          >
            Next &rarr;
          </Button>
        </div>
      </div>
    </>
  );
};
