import React, { useEffect, useState } from "react";
import grapejs from "grapesjs";
import gjsPresetWebpage from "grapesjs-preset-webpage";
import { useRef } from "react";
const AddGrapes = () => {
  const [editor, setEditor] = useState(null);
  const getData = useRef(null);

  useEffect(() => {
    const editor = grapejs.init({
      container: "#editor",
      storageManager: false,
      blockManager: {
        appendTo: "#blocks",
      },
      styleManager: {
        appendTo: "#styles-container",
      },
      layerManager: {
        appendTo: "#layers-container",
      },
      traitManager: {
        appendTo: "#trait-container",
      },
      selectorManager: {
        appendTo: "#styles-container",
      },
      deviceManager: {},
      plugins: [],
      pluginsOpts: {},

      plugins: [gjsPresetWebpage],
      pluginsOpts: {
        gjsPresetWebpage: {},
      },
    });
    const a = setInterval(() => {
      setEditor(editor);
    }, 2000);
    clearInterval(a);
  }, []);
  var html = editor?.getHtml();
  console.log(html);
  // Getting only CSS:

  var css = editor?.getCss();
  console.log(css);
  const handleSubmit = () => {
    console.log(editor.getHtml());
  };

  return (
    <>
      <div>
        <button onClick={() => handleSubmit()}>save</button>
      </div>
      <div id="editor" ref={getData}></div>;
    </>
  );
};
export default AddGrapes;
