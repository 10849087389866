import React from "react";
import { MdPhotoCameraFront } from "react-icons/md";
import { MdOutlinePhotoCameraFront } from "react-icons/md";
import { LiaFirstdraft } from "react-icons/lia";
import { MdPeopleOutline } from "react-icons/md";
import { Link } from "react-router-dom";

function Addinterview() {
  return (
    <div className=''>
      <div className='d-flex gap-5'>
        <Link className='bg-white w-100 p-5 rounded' to='/interview-pro/interview/add/new-mock'>
          <div className=''>
            <MdPhotoCameraFront fontSize={30} className='me-3' />
            Create Mock Interview
          </div>
        </Link>
        {/* <div className='bg-white w-100 p-5 rounded'>
          <MdOutlinePhotoCameraFront fontSize={30} className='me-3' />
          Create Job Interview</div> */}
      </div>
      {/* <div className='d-flex gap-5 mt-5'>
        <div className='bg-white w-100 p-5 rounded'>
          <LiaFirstdraft fontSize={30} className='me-3' />
          Draft Interviews</div>
        <div className='bg-white w-100 p-5 rounded'>
          <MdPeopleOutline fontSize={30} className='me-3' />
          Published Interviews</div>

      </div> */}
    </div >
  )
}

export default Addinterview;
