import { id } from "chartjs-plugin-streaming";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addOption,
  hintAction,
  marksAction,
  questionName,
  removeAssessment,
} from "../../../../../store/actions/section";
import { UploadForm } from "../UploadForm";
import OptionItem from "./OptionItem";

const AssignmentItem = ({ item }) => {
  const dispatch = useDispatch();

  const AddOption = () => {
    let data = {
      options: {
        courseId: id,
        chapterID: item.chapterID,
        sectionID: item.sectionID,
        qid: item.qid,
        option: {
          name: "",
          value: "",
          image: "",
          optId: Math.random().toString(36).substr(2, 8),
        },
      },
    };
    dispatch(addOption(data.options));
  };
  const id = window.location.pathname.split("/")[2];

  const questionHandler = (e) => {
    const data = {
      courseId: id,
      chapterID: item.chapterID,
      sectionID: item.sectionID,
      qid: item.qid,
      question: e.target.value,
    };
    dispatch(questionName(data));
  };

  const hintHandler = (e) => {
    const data = {
      courseId: id,
      chapterID: item.chapterID,
      sectionID: item.sectionID,
      qid: item.qid,
      hint: e.target.value,
    };
    dispatch(hintAction(data));
  };
  const marksHandler = (e) => {
    const data = {
      courseId: id,
      chapterID: item.chapterID,
      sectionID: item.sectionID,
      qid: item.qid,
      marks: e.target.value,
    };
    dispatch(marksAction(data));
  };
  const chapterID = item.chapterID;
  const sectionID = item.sectionID;
  const qid = item.qid;

  const removeQuestion = () => {
    const data = {
      courseId: id,
      chapterID: item.chapterID,
      sectionID: item.sectionID,
      qid: item.qid,
    };
    dispatch(removeAssessment(data));
  };

  return (
    <>
      <div className="back-ground-light mt-3">
        <div className="card-background">
          <div className="d-flex justify-content-end mb-2 cursor-pointer">
            <i
              class="fa-solid fa-trash-can"
              onClick={removeQuestion}
              style={{ color: "red" }}
            />
          </div>
          <input
            type="text"
            className="text-center"
            placeholder="write question here"
            name="question"
            defaultValue={item.question || ""}
            onChange={(e) => questionHandler(e)}
          />
          {item?.options?.map((option) => (
            <OptionItem {...{ option, chapterID, sectionID, qid }} />
          ))}
          <div className="d-flex justify-content-between">
            <input
              type="text"
              placeholder="Hints"
              name="hints"
              defaultValue={item.hint || ""}
              onChange={(e) => hintHandler(e)}
              style={{ width: "20%" }}
              className="form-control w-20  text-center embedcode"
            />
            <input
              type="text"
              placeholder="marks"
              name="marks"
              defaultValue={item.marks || ""}
              onChange={(e) => marksHandler(e)}
              style={{ width: "20%" }}
              className="form-control w-20  text-center embedcode"
            />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button
              className="btn btn-primary"
              variant="success"
              onClick={() => AddOption()}
            >
              + Add Option
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentItem;
